<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Наименование"
          placeholder="Введите наименование пользователя"
          v-model="companyUser.user.fullname"
          hide-details
          class="rounded-0"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="companyUser.user.roleId == 5">
      <v-col cols="10">
        <v-autocomplete
          outlined
          dense
          :color="company.data.companyColor.color1"
          :items="usaPharmacies.data"
          item-text="pharmName"
          item-value="pharmName"
          v-model="selectedUsaPharm"
          label="Аптеки СПА"
          hide-details
          return-object
          prepend-icon="mdi-format-list-bulleted-type"
          class="rounded-0"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
         <v-btn
          outlined
          tile
          small
          color="green"
          @click="commit()"
          >Применить</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          outlined
          tile
          :color="company.data.companyColor.color1"
          @click="update()"
          >Редактировать</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "UpdateUser",
  data: () => ({
    selectedUsaPharm: null
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyUser: "companyUser/GET_COMPANY_USER",
      getUser: "user/GET_USER",
      getUsaPharmacies: "pharmacy/GET_PHARMACIES"
    }),
    companyUser: {
      get: function () {
        return this.getCompanyUser;
      },
    },
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    user: {
      get: function () {
        return this.getUser;
      },
    },
    usaPharmacies: {
         get: function () {
        return this.getUsaPharmacies;
      },
    }
  },
  methods: {
    ...mapActions({
      updateUserFullnameFromApi: "user/UPDATE_FULLNAME_FROM_API",
      getUsaPharmaciesFromApi: "pharmacy/GET_USA_PHARMACIES_FROM_API"
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async update() {
      if (
        this.companyUser != null &&
        this.companyUser.user.fullname.length > 0
      ) {
        await this.updateUserFullnameFromApi({
          userId: this.companyUser.user.id,
          fullname: this.companyUser.user.fullname.trim(),
        });
        if (this.user.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Пользователь успешно обновлен!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при обновлении пользователя!",
          });
        }
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Введите корректно данные!",
        });
      }
    },
    commit(){
        this.companyUser.user.fullname = this.selectedUsaPharm.pharmName;
    }
  },
  async created(){
    if(this.companyUser.user.roleId == 5)
        this.getUsaPharmaciesFromApi();
  }
};
</script>
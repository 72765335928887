export default {
    namespaced: true,
    state: {
        appBarSettings: {
            show: false,
            color: 'primary'
        },
        snackbar: {
            show: false,
            timeout: 3000,
            color: 'green',
            text: ''
        },
        dialogSettings: {
            show: false,
            title: "Модальное окно",
            color: 'primary',
            persistent: true,
            maxWidth: 600,
            actionId: 0,
            closeButton: false,
        },
        sideBarSettings: {
            clipped: true,
            drawer: false,
            color: 'white',
            items: [],
            show: false,
            companySelectShow: false,
            selectedCompany: null,
            companies: null,
            selectedMenuItemId: null,
            showCompanySelect: true
        },
        confirmSettings: {
            isConfirmed: false,
            title: '',
        }
    },
    getters: {
        GET_APP_BAR_SETTINGS(state) {
            return state.appBarSettings;
        },
        GET_SNACKBAR(state) {
            return state.snackbar;
        },
        GET_DIALOG_SETTINGS(state) {
            return state.dialogSettings;
        },
        GET_SIDEBAR_SETTINGS(state) {
            return state.sideBarSettings;
        },
        GET_CONFIRM_SETTINGS(state) {
            return state.confirmSettings;
        }
    },
    mutations: {
        SET_APP_BAR_SETTINGS(state, payload) {
            state.appBarSettings = payload;
        },
        SET_SNACKBAR(state, payload) {
            state.snackbar = payload;
        },
        SET_DIALOG_SETTINGS(state, payload) {
            state.dialogSettings = payload;
        },
        SET_SIDEBAR_SETTINGS(state, payload) {
            state.sideBarSettings = payload;
        },
        SET_CONFIRM_SETTINGS(state, payload) {
            state.confirmSettings = payload;
        }
    },
    actions: {
        COMMIT_APP_BAR_SETTINGS({ commit }, payload) {
            commit('SET_APP_BAR_SETTINGS', payload);
        },
        GENERATE_SNACKBAR({ commit }, payload) {
            commit('SET_SNACKBAR', payload);
        },
        COMMIT_DIALOG_SETTINGS({ commit }, payload) {
            commit('SET_DIALOG_SETTINGS', payload);
        },
        COMMIT_SIDEBAR_SETTINGS({ commit }, payload) {
            commit('SET_SIDEBAR_SETTINGS', payload);
        },
        COMMIT_CONFIRM_SETTINGS({ commit }, payload) {
            commit('SET_CONFIRM_SETTINGS', payload)
        }
    }
}
import axios from 'axios'
import connections from '../configs/connections';

const instance = axios.create({
    baseURL: connections.connections.api,
    headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('identity'))?.data.accessToken}
})

instance.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (error.status === 401 || error.status === 403)
    {
       localStorage.removeItem('identity');
       window.location.assign('/account/login');
    }
})

export default instance

export default function(instance){
    return{
        GetAll(){
            return instance.get('/Pharmacy/GetAll');
        },
        GetUsa(){
            return instance.get('/Pharmacy/GetUsa');
        },
    }
}

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card
          v-if="company != null"
          outlined
          class="mx-3"
          tile
          :color="company.data.companyColor.color1"
        >
          <v-card-title class="py-2">
            <v-icon :color="company.data.companyColor.color3"
              >mdi-watermark</v-icon
            >
            <span
              class="text-overline mx-2"
              :style="'color: ' + company.data.companyColor.color3"
              >Бренды</span
            >
            <v-spacer />
            <v-btn-toggle tile>
              <v-btn
                small
                :color="company.data.companyColor.color2"
                outlined
                tile
                @click="apply()"
                >Применить</v-btn
              >
            </v-btn-toggle>
          </v-card-title>
          <v-divider />
          <v-row>
            <v-col cols="12">
              <v-data-table
                :checkbox-color="company.data.companyColor.color2"
                v-model="selectedBrands"
                :headers="headers"
                :items="brands.data"
                class="elevation-0 rounded-0"
                :single-select="false"
                item-key="id"
                show-select
                disable-pagination
                hide-default-footer
                fixed-header
                height="calc(100vh - 196px)"
              >
                <template v-slot:item.settings="{ item }">
                  <v-btn
                    small
                    fab
                    text
                    :color="company.data.companyColor.color2"
                    @click="openSkus(item.id)"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "Brands",
  computed: {
    ...mapGetters({
      getBrands: "brand/GET_BRANDS",
      getCompany: "company/GET_COMPANY",
      getCompanyBrands: "companyBrand/GET_COMPANY_BRANDS",
    }),
    brands: {
      get: function () {
        return this.getBrands;
      },
      set: function (newValue) {
        this.setBrands(newValue);
      },
    },
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    companyBrands: {
      get: function () {
        return this.getCompanyBrands;
      },
      set: function (newValue) {
        this.setCompanyBrands(newValue);
      },
    },
  },
  data: () => ({
    dialogAction: dialogAction,
    selectedBrands: [],
    headers: [
      { text: "ID", align: "center", value: "id" },
      { text: "Наименование", align: "center", value: "name" },
      { text: "Настройка", align: "center", value: "settings" },
    ],
  }),
  methods: {
    ...mapActions({
      getBrandsFromApi: "brand/GET_BRANDS_FROM_API",
      commitSelectedBrand: "brand/COMMIT_SELECTED_BRAND",
      commitCompanyBrandsFromApi: "companyBrand/COMMIT_FROM_API",
    }),
    ...mapMutations({
      setBrands: "brand/SET_BRANDS",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setSnackBar: "common/SET_SNACKBAR",
      setCompanyBrands: "companyBrand/SET_COMPANY_BRANDS",
    }),
    openSkus(id) {
      this.commitSelectedBrand(this.brands.data.find((x) => x.id == id));
      this.setDialogSettings({
        show: true,
        title: "SKU бренда",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 600,
        actionId: this.dialogAction.BRAND_SKUS,
      });
    },
    async apply() {
      if (this.selectedBrands == null || this.selectedBrands.length == 0) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Выберите хотя бы один элемент из списка!",
        });
      } else {
        let companyId = this.company.data.id;
        await this.commitCompanyBrandsFromApi({
          list: this.selectedBrands.map((x) => ({
            brandId: x.id,
            companyId: companyId,
          })),
        });
        if (this.companyBrands.succeeded) {
          this.company.data.companyBrands = this.companyBrands.data;
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Изменения успешно внесены!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при внесении изменений!",
          });
        }
      }
    },
    initializeSelectedBrands() {
      this.selectedBrands = this.brands.data.filter((x) =>
        this.company.data.companyBrands.map((x) => x.brandId).includes(x.id)
      );
    },
  },
  watch: {
    company: function () {
      if (this.company != null) {
        this.initializeSelectedBrands();
      }
    },
  },
  async mounted() {
    await this.getBrandsFromApi();
    this.initializeSelectedBrands();
  },
};
</script>
import instance from './instance'
import accountModule from './account'
import companyModule from './company'
import companyColorModule from './companyColor'
import userModule from './user'
import companyUserModule from './companyUser'
import companyBrandModule from './companyBrand'
import companyUserCompanyBrandModule from './companyUserCompanyBrand'
import brandModule from './brand'
import companyPeriodModule from './companyPeriod'
import companyPlanModule from './companyPlan'
import companyUserCompanyBrandPlanModule from './companyUserCompanyBrandPlan'
import calculateModule from './calculate'
import reportModule from './report'
import pharmacyModule from './pharmacy'
import userPharmModule from './userPharm'
import companyUserPeriodPlanModule from './companyUserPeriodPlan'
import cacheModule from './cache'

export default{
    account: accountModule(instance),
    company: companyModule(instance),
    companyColor: companyColorModule(instance),
    user: userModule(instance),
    companyUser: companyUserModule(instance),
    companyBrand: companyBrandModule(instance),
    companyUserCompanyBrand: companyUserCompanyBrandModule(instance),
    brand: brandModule(instance),
    companyPeriod: companyPeriodModule(instance),
    companyUserCompanyBrandPlan: companyUserCompanyBrandPlanModule(instance),
    calculate: calculateModule(instance),
    report: reportModule(instance),
    pharmacy: pharmacyModule(instance),
    userPharm: userPharmModule(instance),
    companyPlan: companyPlanModule(instance),
    companyUserPeriodPlan: companyUserPeriodPlanModule(instance),
    cache: cacheModule(instance)
}
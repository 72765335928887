<template>
  <div></div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ReLogin",
  data: () => ({
    login: "",
    password: "",
  }),
  computed: {
    ...mapGetters({
      getLoginInfo: "account/GET_LOGIN_INFO",
    }),
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
      set: function (newValue) {
        this.setLoginInfo(newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      loginFromAPI: "account/LOGIN",
    }),
    ...mapMutations({
      setLoginInfo: "account/SET_LOGIN_INFO",
    }),
    routeToHomePage(roleId) {
      let path;
      switch (roleId) {
        case 1:
          path = "/company";
          break;
        case 2:
          path = "/producer/admin";
          break;
        case 3:
          path = "/producer/regionalManager";
          break;
        case 4:
          path = "/producer/medicalRepresentative";
          break;
        case 5:
          path = "/pharmacy";
          break;
        default:
          path = "/error/401";
          break;
      }
      var data = this.$router.resolve({
        path: path,
      });
      document.location.href = data.href;
    },
    async enter() {
      await this.loginFromAPI({
        Login: this.login,
        Password: this.password,
      });
      if (this.loginInfo.succeeded) {
        this.routeToHomePage(this.loginInfo.data.roleId);
      }
    },
  },
  async mounted() {
    this.login = this.$route.params.login;
    this.password = this.$route.params.password;

    await this.enter();
  },
};
</script>
<template>
  <v-container>
    <v-row class="justify-center align-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-text-field
          outlined
          dense
          label="Логин"
          placeholder="Введите логин"
          v-model="login"
          hide-details
          class="rounded-0"
          prepend-icon="mdi-login"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-text-field
          outlined
          dense
          label="Наименование"
          placeholder="Введите наименование"
          v-model="name"
          hide-details
          class="rounded-0"
          prepend-icon="mdi-domain"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-text-field
          outlined
          dense
          label="Пароль"
          placeholder="Введите пароль"
          v-model="password"
          hide-details
          class="rounded-0"
          type="password"
          prepend-icon="mdi-lock"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-center align-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-autocomplete
          outlined
          dense
          :color="company.data.companyColor.color1"
          v-model="selectedRole"
          :items="roles"
          item-text="name"
          item-value="id"
          label="Роль пользователя"
          hide-details
          return-object
          prepend-icon="mdi-account-supervisor"
          class="rounded-0"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-divider class="my-2" />

    <v-row v-if="selectedRole.id == 5">
      <v-col cols="12">
        <v-row>
          <v-col cols="8">
            <v-text-field
              outlined
              dense
              label="Поиск"
              placeholder="Введите текст для поиска"
              v-model="search"
              hide-details
              class="rounded-0"
              :color="company.data.companyColor.color1"
              prepend-icon="mdi-cloud-search-outline"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              :color="company.data.companyColor.color1"
              v-model="selectedSearchColumn"
              :items="searchColumns"
              item-text="name"
              item-value="id"
              label="Столбец"
              hide-details
              return-object
              prepend-icon="mdi-format-list-bulleted-type"
              class="rounded-0"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card
              outlined
              class="mx-2"
              tile
              :color="company.data.companyColor.color1"
            >
              <v-card-title class="py-2">
                <v-icon :color="company.data.companyColor.color3"
                  >mdi-plus-circle</v-icon
                >
                <span
                  class="text-overline mx-2"
                  :style="'color: ' + company.data.companyColor.color3"
                  >Аптеки</span
                >
              </v-card-title>
              <v-divider />
              <v-data-table
                :headers="headers"
                item-key="first_id"
                :items="list"
                class="elevation-0"
                fixed-header
                height="60vh"
                :loading="loading"
              >
                <v-progress-linear
                  v-show="loading"
                  slot="progress"
                  :color="company.data.companyColor.color1"
                  indeterminate
                ></v-progress-linear>
                <template v-slot:item.actions="{ item }">
                  <v-btn x-small fab text color="green" @click="select(item)">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card
              outlined
              class="mx-2"
              tile
              :color="company.data.companyColor.color1"
            >
              <v-card-title class="py-2">
                <v-icon :color="company.data.companyColor.color3"
                  >mdi-minus-circle</v-icon
                >
                <span
                  class="text-overline mx-2"
                  :style="'color: ' + company.data.companyColor.color3"
                  >Выбранные аптеки</span
                >
              </v-card-title>
              <v-divider />
              <v-data-table
                :headers="headers1"
                item-key="second_id"
                :items="selectedPharms"
                class="elevation-0"
                fixed-header
                height="60vh"
                :loading="loading"
              >
                <v-progress-linear
                  v-show="loading"
                  slot="progress"
                  :color="company.data.companyColor.color1"
                  indeterminate
                ></v-progress-linear>
                <template v-slot:item.actions="{ item }">
                  <v-btn x-small fab text color="red" @click="remove(item)">
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="green" outlined tile @click="apply()">Применить</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "UserPharms",
  computed: {
    ...mapGetters({
      getPharms: "pharmacy/GET_PHARMACIES",
      getCompany: "company/GET_COMPANY",
      getUserPharms: "userPharm/GET_USER_PHARMS",
      getUser: "user/GET_USER",
    }),
    pharmacies: {
      get: function () {
        return this.getPharms;
      },
    },
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    userPharms: {
      get: function () {
        return this.getUserPharms;
      },
      set: function (newValue) {
        this.setUserPharms(newValue);
      },
    },
    user: {
      get: function () {
        return this.getUser;
      },
      set: function (newValue) {
        this.setUser(newValue);
      },
    },
  },
  data: () => ({
    login: null,
    name: null,
    password: null,
    list: [],
    search: null,
    loading: false,
    selectedPharms: [],
    selectedSearchColumn: null,
    searchColumns: null,
    headers: [
      { text: "Логин", align: "center", value: "login" },
      { text: "Компания", align: "center", value: "companyName" },
      { text: "Наименование", align: "center", value: "name" },
      { text: "Телефон", align: "center", value: "phone" },
      { text: "Город", align: "center", value: "cityName" },
      { text: "Адрес", align: "center", value: "address" },
      { text: "БИН", align: "center", value: "bin" },
      { text: "Действия", align: "center", value: "actions", sortable: false },
    ],
    headers1: [
      { text: "Логин", align: "center", value: "login" },
      { text: "Компания", align: "center", value: "companyName" },
      { text: "Наименование", align: "center", value: "name" },
      { text: "Телефон", align: "center", value: "phone" },
      { text: "Город", align: "center", value: "cityName" },
      { text: "Адрес", align: "center", value: "address" },
      { text: "БИН", align: "center", value: "bin" },
      { text: "Действия", align: "center", value: "actions", sortable: false },
    ],
    roles: [
      { id: 2, name: "Администратор (Производитель)" },
      { id: 3, name: "Региональный менеджер" },
      { id: 4, name: "Медицинский представитель" },
      { id: 5, name: "Сеть" },
    ],
    selectedRole: { id: 5, name: "Сеть" },
  }),
  methods: {
    ...mapActions({
      getPharmaciesFromAPI: "pharmacy/GET_PHARMACIES_FROM_API",
      commitUserPharmFromApi: "userPharm/COMMIT_FROM_API",
      addUserFromAPI: "user/ADD_FROM_API",
    }),
    ...mapMutations({
      setPharmacies: "pharmacy/SET_PHARMACIES",
      setUserPharms: "userPharm/SET_USER_PHARMS",
      setSnackBar: "common/SET_SNACKBAR",
      setUser: "user/SET_USER",
    }),
    select(item) {
      var index = this.list.indexOf(item);
      if (index >= 0) this.list.splice(index, 1);
      this.selectedPharms.push(item);
    },
    remove(item) {
      var index = this.selectedPharms.indexOf(item);
      if (index >= 0) {
        this.selectedPharms.splice(index, 1);
        this.list.unshift(item);
      }
    },
    async apply() {
      if (
        this.login == null ||
        this.name == null ||
        this.password == null ||
        this.selectedRole == null ||
        this.login.length < 6 ||
        this.name.length < 3 ||
        this.password.length < 6
      ) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Заполните корректно поля!",
        });
      } else {
        await this.addUserFromAPI({
          login: this.login,
          fullname: this.name,
          password: this.password,
          roleId: this.selectedRole.id,
        });

        if (this.user.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Пользователь успешно создан!",
          });

          if (this.selectedRole.id == 5) {
            if (
              this.selectedPharms == null ||
              this.selectedPharms.length == 0
            ) {
              this.setSnackBar({
                show: true,
                timeout: 3000,
                color: "red",
                text: "Выберите хотя бы один элемент из списка аптек!",
              });
            } else {
              let userId = this.user.data.id;
              await this.commitUserPharmFromApi({
                list: this.selectedPharms.map((x) => ({
                  fullname: x.name,
                  cityId: x.cityId,
                  address: x.address,
                  userId: userId,
                  pharmcenterUserId: x.id,
                  bin: x.bin,
                })),
              });
              if (this.userPharms.succeeded) {
                this.setSnackBar({
                  show: true,
                  timeout: 3000,
                  color: "green",
                  text: "Изменения успешно внесены!",
                });
              } else {
                this.setSnackBar({
                  show: true,
                  timeout: 3000,
                  color: "red",
                  text: "Ошибка при внесении изменений!",
                });
              }
            }
          }
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при создании пользователя!",
          });
        }
      }
    },
  },
  watch: {
    search: function () {
      if (this.search == null || this.search.length == 0) {
        this.list = this.pharmacies.data.filter(
          (x) => !this.selectedPharms.includes(x)
        );
      } else {
        if (this.selectedSearchColumn != null) {
          let selectedPharmIds = this.selectedPharms.map((x) => x.id);
          let columnName = this.selectedSearchColumn.id;
          this.list = this.pharmacies.data.filter(
            (x) =>
              x[columnName].toLowerCase().includes(this.search.toLowerCase()) &&
              !selectedPharmIds.includes(x.id)
          );
        }
      }
    },
  },
  async mounted() {
    this.searchColumns = this.headers.map((x) => ({
      id: x.value,
      name: x.text,
    }));

    this.selectedSearchColumn = this.searchColumns[0];
    this.loading = true;

    await this.getPharmaciesFromAPI();
    // let selectedPharmIds = this.companyUser.user.userPharms.map(
    //   (x) => x.pharmcenterUserId
    // );

    // this.selectedPharms = this.pharmacies.data.filter((x) =>
    //   selectedPharmIds.includes(x.id)
    // );

    this.list = this.pharmacies.data;

    // this.list = this.pharmacies.data.filter(
    //   (x) => !selectedPharmIds.includes(x.id)
    // );

    this.loading = false;
  },
};
</script>
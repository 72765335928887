<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Наименование компании"
          placeholder="Введите наименование компании"
          v-model="name"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Ссылка на логотип компании"
          placeholder="Введите ссылку на логотип компании"
          v-model="logoLink"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn outlined tile color="green" @click="add()">Добавить</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "AddCompany",
  data: () => ({
    name: null,
    logoLink: null
  }),
  methods: {
      ...mapActions({
          addCompanyFromApi: "company/ADD_COMPANY_FROM_API"
      }),
      ...mapMutations({
          setDialogSettings: "common/SET_DIALOG_SETTINGS",
      }),
      async add(){
          if((this.name != null && this.logoLink != null) && (this.name.length >= 3 && this.name.length <= 300) && (this.logoLink.length >= 3 && this.logoLink.length <= 300))
          await this.addCompanyFromApi({
              name: this.name,
              logo: this.logoLink
          })

          this.setDialogSettings({
          show: false,
          title: "Модальное окно",
          color:  "primary",
          persistent: true,
          maxWidth: 600,
        });
      }
  }
};
</script>
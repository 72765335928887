import userPharm from '../api/index'

const Commit = (query) => {
    return new Promise((resolve) => {
        resolve(userPharm.userPharm.Commit(query))
    })
}

const Update = (query) => {
    return new Promise((resolve) => {
        resolve(userPharm.userPharm.Update(query))
    })
}

export default {
    namespaced: true,
    state: {
        userPharms: null,
        userPharm: null,
    },
    getters: {
        GET_USER_PHARMS(state) {
            return state.userPharms;
        },
        GET_USER_PHARM(state) {
            return state.userPharm;
        },
    },
    mutations: {
        SET_USER_PHARMS(state, payload) {
            state.userPharms = payload;
        },
        SET_USER_PHARM(state, payload) {
            state.userPharm = payload;
        },
    },
    actions: {
        async COMMIT_FROM_API({ commit }, payload) {
            await Commit(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_USER_PHARMS', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_USER_PHARMS', { succeeded: false });
            })
        },
        async UPDATE_FROM_API({ commit }, payload) {
            await Update(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_USER_PHARM', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_USER_PHARM', { succeeded: false });
            })
        },
    }
}

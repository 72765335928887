import Vue from 'vue'
import Vuex from 'vuex'
import account from './account'
import common from './common'
import company from './company'
import user from './user'
import companyUser from './companyUser'
import companyUserCompanyBrand from './companyUserCompanyBrand'
import brand from './brand'
import companyBrand from './companyBrand'
import companyPeriod from './companyPeriod'
import companyPlan from './companyPlan'
import companyUserCompanyBrandPlan from './companyUserCompanyBrandPlan'
import calculate from './calculate'
import report from './report'
import pharmacy from './pharmacy'
import userPharm from './userPharm'
import companyUserPeriodPlan from './companyUserPeriodPlan'
import cache from './cache'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    common,
    company,
    user,
    companyUser,
    companyUserCompanyBrand,
    brand,
    companyBrand,
    companyPeriod,
    companyUserCompanyBrandPlan,
    calculate,
    report,
    pharmacy,
    userPharm,
    companyPlan,
    companyUserPeriodPlan,
    cache
  }
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-3",attrs:{"outlined":"","tile":"","color":_vm.company.data.companyColor.color1}},[_c('v-card-title',{staticClass:"py-2"},[_c('v-icon',{attrs:{"color":_vm.company.data.companyColor.color3}},[_vm._v("mdi-chart-box")]),_c('span',{staticClass:"text-overline mx-2",style:('color: ' + _vm.company.data.companyColor.color3)},[_vm._v("Отчеты")])],1),_c('v-row',{staticClass:"white mx-1 my-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-autocomplete',{staticClass:"rounded-0",attrs:{"outlined":"","dense":"","color":_vm.company.data.companyColor.color1,"items":_vm.reportTypes,"item-text":"name","item-value":"id","label":"Тип отчета","hide-details":"","return-object":"","prepend-icon":"mdi-format-list-bulleted-type"},model:{value:(_vm.selectedReportType),callback:function ($$v) {_vm.selectedReportType=$$v},expression:"selectedReportType"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-autocomplete',{staticClass:"rounded-0",attrs:{"outlined":"","dense":"","color":_vm.company.data.companyColor.color1,"items":_vm.company.data.companyBrands.filter(
                  function (x) { return x.companyPeriodId == _vm.selectedPeriod.id; }
                ),"item-text":"brand.name","item-value":"brand.id","label":"Бренды","hide-details":"","return-object":"","prepend-icon":"mdi-watermark","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [(index === 0 || index === 1)?_c('v-chip',{attrs:{"small":"","color":_vm.company.data.companyColor.color1,"outlined":""}},[_c('span',[_vm._v(_vm._s(item.brand.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedBrands.length - 2)+" др.)")]):_vm._e()]}}]),model:{value:(_vm.selectedBrands),callback:function ($$v) {_vm.selectedBrands=$$v},expression:"selectedBrands"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","color":_vm.company.data.companyColor.color1,"items":_vm.company.data.companyUsers.filter(function (x) { return x.user.roleId == 5; }),"item-text":"user.fullname","item-value":"user.id","label":"Аптеки","hide-details":"","return-object":"","prepend-icon":"mdi-mortar-pestle-plus","small-chips":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [(index === 0 || index === 1)?_c('v-chip',{attrs:{"small":"","color":_vm.company.data.companyColor.color1,"outlined":""}},[_c('span',[_vm._v(_vm._s(item.user.fullname))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedUsers.length - 2)+" др.)")]):_vm._e()]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-autocomplete',{staticClass:"rounded-0",attrs:{"outlined":"","dense":"","color":_vm.company.data.companyColor.color1,"items":_vm.company.data.companyPeriods,"item-text":"name","item-value":"id","label":"Выберите период","hide-details":"","return-object":"","prepend-icon":"mdi-calendar-clock"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":_vm.company.data.companyColor.color1}},[_vm._v("mdi-calendar-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(new Date(data.item.dateFrom).toLocaleDateString())+" - "+_vm._s(new Date(data.item.dateTo).toLocaleDateString()))])],1)]}}]),model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"tile":"","outlined":"","color":_vm.company.data.companyColor.color1,"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.generate()}}},[_vm._v("Сформировать")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
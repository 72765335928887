import user from '../api/index'

const GetByExcludeCompany = (query) => {
    return new Promise((resolve) => {
        resolve(user.user.GetByExcludeCompany(query))
    })
}

const Add = (query) => {
    return new Promise((resolve) => {
        resolve(user.user.Add(query))
    })
}

const GetAllNetworks = () => {
    return new Promise((resolve) => {
        resolve(user.user.GetAllNetworks())
    })
}

const UpdateFullname = (query) => {
    return new Promise((resolve) => {
        resolve(user.user.UpdateFullname(query))
    })
}

export default {
    namespaced: true,
    state: {
        usersByExcludeCompany: null,
        user: null,
        networks: null
    },
    getters: {
        GET_USERS_BY_EXCLUDE_COMPANY(state) {
            return state.usersByExcludeCompany;
        },
        GET_USER(state){
            return state.user;
        },
        GET_ALL_NETWORKS(state){
            return state.networks;
        }
    },
    mutations: {
        SET_USERS_BY_EXCLUDE_COMPANY(state, payload) {
            state.usersByExcludeCompany = payload;
        },
        SET_USER(state, payload){
            state.user = payload;
        },
        SET_ALL_NETWORKS(state, payload){
            state.networks = payload;
        }
    },
    actions: {
        async GET_USERS_BY_EXCLUDE_COMPANY_FROM_API({ commit }, payload) {
            await GetByExcludeCompany(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_USERS_BY_EXCLUDE_COMPANY', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_USERS_BY_EXCLUDE_COMPANY', { succeeded: false });
            })
        },
        async GET_ALL_NETWORKS_FROM_API({ commit }, payload) {
            await GetAllNetworks(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_ALL_NETWORKS', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_ALL_NETWORKS', { succeeded: false });
            })
        },
        async ADD_FROM_API({ commit }, payload) {
            await Add(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_USER', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_USER', { succeeded: false });
            })
        },
        async UPDATE_FULLNAME_FROM_API({ commit }, payload) {
            await UpdateFullname(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_USER', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_USER', { succeeded: false });
            })
        }
    }
}
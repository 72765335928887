<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selectedBrands"
          :headers="headers"
          :single-select="false"
          item-key="id"
          :items="company.data.companyBrands"
          class="elevation-3"
          show-select
          disable-pagination
          hide-default-footer
          fixed-header
          height="60vh"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn outlined :color="company.data.companyColor.color1" tile @click="apply()">Применить</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ControlUserBrands",
  data: () => ({
    selectedBrands: [],
    headers: [{ text: "Бренд", align: "center", value: "brand.name" }],
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyUser: "companyUser/GET_COMPANY_USER",
      getCompanyUserCompanyBrands:
        "companyUserCompanyBrand/GET_COMPANY_USER_COMPANY_BRANDS",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    companyUser: {
      get: function () {
        return this.getCompanyUser;
      },
    },
    companyUserCompanyBrands: {
      get: function () {
        return this.getCompanyUserCompanyBrands;
      },
    },
  },
  methods: {
    ...mapActions({
      commitFromApi: "companyUserCompanyBrand/COMMIT_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
    }),
    async apply() {
      if (this.selectedBrands == null || this.selectedBrands.length == 0) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Выберите хотя бы один элемент из списка!",
        });
      } else {
        let companyUserId = this.companyUser.id;
        await this.commitFromApi({
          list: this.selectedBrands.map((x) => ({
            companyUserId: companyUserId,
            companyBrandId: x.id,
          })),
        });
        if (this.companyUserCompanyBrands.succeeded) {
          this.companyUser.companyUserCompanyBrands = this.companyUserCompanyBrands.data;
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Изменения успешно внесены!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при внесении изменений!",
          });
        }
      }
    },
    initialize() {
      let companyUserBrands = this.companyUser.companyUserCompanyBrands.map(
        (x) => x.companyBrandId
      );
      this.selectedBrands = this.company.data.companyBrands.filter((x) =>
        companyUserBrands.includes(x.id)
      );
    },
  },
  watch: {
    companyUser: {
      deep: true,
      handler: function () {
        this.initialize();
      },
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
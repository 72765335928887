<template>
  <v-container class="d-flex justify-center align-center">
    <v-row style="width: 80vw">
      <v-col cols="12">
        <v-card
          elevation="0"
          height="calc(100vh - 96px)"
          class="overflow-y-auto"
          outlined
          tile
        >
          <v-card-text>
            <v-img src="/static/about.jpg" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "HowToConnect",
  data: () => ({
    
  }),
};
</script>
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card
          outlined
          class="mx-3"
          tile
          :color="company.data.companyColor.color1"
        >
          <v-card-title class="py-2">
            <v-icon :color="company.data.companyColor.color3"
              >mdi-account-group</v-icon
            >
            <span
              class="text-overline mx-2"
              :style="'color: ' + company.data.companyColor.color3"
              >Пользователи</span
            >
            <v-spacer />
            <v-btn-toggle class="mx-3" tile>
              <v-btn small color="green" outlined tile @click="create()"
                >Создать</v-btn
              >
              <v-btn
                small
                :color="company.data.companyColor.color2"
                outlined
                tile
                @click="edit()"
                >Изменить</v-btn
              >
              <v-btn small color="black" outlined tile @click="bindings()"
                >Привязки</v-btn
              >
            </v-btn-toggle>
            <v-spacer />
            <v-btn-toggle class="mx-3" tile>
              <v-btn
                small
                :color="company.data.companyColor.color2"
                outlined
                tile
                @click="add()"
                >Добавить</v-btn
              >
              <v-btn small color="red" outlined tile @click="confirmRemove()"
                >Удалить</v-btn
              >
            </v-btn-toggle>
          </v-card-title>
          <v-card-subtitle class="py-2 mt-1 white">
            <v-row>
              <v-col cols="8">
                <v-text-field
                  outlined
                  dense
                  label="Поиск"
                  placeholder="Введите текст для поиска"
                  v-model="search"
                  hide-details
                  class="rounded-0"
                  :color="company.data.companyColor.color1"
                  prepend-icon="mdi-cloud-search-outline"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  :color="company.data.companyColor.color1"
                  v-model="selectedSearchColumn"
                  :items="searchColumns"
                  item-text="name"
                  item-value="id"
                  label="Столбец"
                  hide-details
                  return-object
                  prepend-icon="mdi-format-list-bulleted-type"
                  class="rounded-0"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider class="grey lighten-2" />
          <v-row>
            <v-col cols="12">
              <v-data-table
                :checkbox-color="company.data.companyColor.color2"
                v-model="selectedUsers"
                :headers="headers"
                :items="list"
                class="elevation-0"
                :single-select="false"
                item-key="id"
                show-select
                fixed-header
                height="calc(100vh - 256px)"
                :footer-props="{
                  'items-per-page-options': [15, -1],
                  'items-per-page-text': 'Элементов на странице: ',
                }"
                :items-per-page="15"
              >
                <template v-slot:item.actions="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        fab
                        text
                        :color="company.data.companyColor.color2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <!-- <v-list-item
                          @click="openControlUserBrandsDialog(item)"
                          v-if="item.user.roleId == 5"
                        >
                          <v-list-item-title
                            >Бренды пользователя</v-list-item-title
                          >
                        </v-list-item> -->
                        <v-list-item
                          @click="openControlUserBrandPlansDialog(item)"
                          v-if="item.user.roleId == 5"
                        >
                          <v-list-item-title>Настройка плана</v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item
                          @click="openUserPharms(item)"
                          v-if="item.user.roleId == 5"
                        >
                          <v-list-item-title>Список аптек</v-list-item-title>
                        </v-list-item> -->
                        <v-list-item @click="openRelationsControlDialog(item)"
                          v-if="![1, 2, 5].includes(item.user.roleId)">
                          <v-list-item-title>Настройка отношений</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openUpdateUserDialog(item)">
                          <v-list-item-title>Переименовать</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "Users",
  data: () => ({
    dialogAction: dialogAction,
    selectedUsers: [],
    headers: [
      { text: "Логин", align: "center", value: "user.login" },
      { text: "ФИО", align: "center", value: "user.fullname" },
      {
        text: "Роль",
        align: "center",
        value: "user.role.name",
      },
      {
        text: "Пароль",
        align: "center",
        value: "user.passwordDecrypted",
      },
      {
        text: "Действия",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
    search: null,
    selectedSearchColumn: null,
    searchColumns: null,
    list: [],
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getDeleteStatus: "companyUser/GET_DELETE_STATUS",
      getConfirmSettings: "common/GET_CONFIRM_SETTINGS",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    deleteStatus: {
      get: function () {
        return this.getDeleteStatus;
      },
    },
    confirmSettigs: {
      get: function () {
        return this.getConfirmSettings;
      }
    }
  },
  methods: {
    ...mapActions({
      getUsersByExcludeCompanyFromAPI:
        "user/GET_USERS_BY_EXCLUDE_COMPANY_FROM_API",
      deleteRangeCompanyUsers: "companyUser/DELETE_RANGE_FROM_API",
      commitCompanyUser: "companyUser/COMMIT_COMPANY_USER",
    }),
    ...mapMutations({
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setSnackBar: "common/SET_SNACKBAR",
      setConfirmSettings: "common/SET_CONFIRM_SETTINGS",
    }),
    async add() {
      await this.getUsersByExcludeCompanyFromAPI({
        companyId: this.company.data.id,
      });
      this.setDialogSettings({
        show: true,
        title: "Добавить пользователя",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 600,
        actionId: this.dialogAction.ADD_USER,
      });
    },
    confirmRemove() {
      if (this.selectedUsers.length > 0) {
        this.setConfirmSettings({
          title: 'Вы уверены, что хотите удалить выбранных пользователей?',
        });
        this.setDialogSettings({
          show: true,
          title: "Подтвердите действие",
          color: this.company.data.companyColor.color1,
          persistent: true,
          maxWidth: 400,
          actionId: this.dialogAction.CONFIRM,
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Выберите пользователей для удаления!",
        });
      }
    },
    async remove() {
      this.setConfirmSettings({
        isConfirmed: false,
      });
      await this.deleteRangeCompanyUsers({
        data: { list: this.selectedUsers.map((x) => ({ id: x.id })) },
      });
      if (this.deleteStatus.data) {
        this.company.data.companyUsers =
          this.company.data.companyUsers.filter(
            (x) => !this.selectedUsers.map((z) => z.id).includes(x.id)
          );
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Пользователь успешно удален!",
        });
        this.selectedUsers = [];
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при удалении пользователя!",
        });
      }
    },
    openControlUserBrandsDialog(item) {
      this.commitCompanyUser(item);
      this.setDialogSettings({
        show: true,
        title: "Настройка брендов пользователя",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 600,
        actionId: this.dialogAction.CONTROL_USER_BRANDS,
      });
    },
    openControlUserBrandPlansDialog(item) {
      this.commitCompanyUser(item);
      this.setDialogSettings({
        show: true,
        title: "Настройка планов бренда пользователя",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 900,
        actionId: this.dialogAction.CONTROL_USER_BRAND_PLANS,
      });
    },
    openUpdateUserDialog(item) {
      this.commitCompanyUser(item);
      this.setDialogSettings({
        show: true,
        title: "Редактирование пользователя",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 900,
        actionId: this.dialogAction.UPDATE_USER,
      });
    },
    openRelationsControlDialog(item) {
      this.commitCompanyUser(item);
      this.setDialogSettings({
        show: true,
        title: "Настройка отношений пользователя",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 900,
        actionId: this.dialogAction.CONTROL_RELATION,
      });
    },
    create() {
      this.setDialogSettings({
        show: true,
        title: "Создание аптечной сети",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 1200,
        actionId: this.dialogAction.USER_PHARMS,
      });
    },
    edit() {
      this.setDialogSettings({
        show: true,
        title: "Изменение аптечной сети",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 1200,
        actionId: this.dialogAction.EDIT_USER_PHARMS,
      });
    },
    bindings() {
      this.setDialogSettings({
        show: true,
        title: "Привязки аптечной сети",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 1200,
        actionId: this.dialogAction.ADD_BINDINGS,
      });
    },
  },
  watch: {
    search: function () {
      if (this.search == null || this.search.length == 0) {
        this.list = this.company.data.companyUsers;
      } else {
        if (this.selectedSearchColumn != null) {
          let columnName = this.selectedSearchColumn.id.split(".");
          switch (columnName.length) {
            case 1:
              this.list = this.company.data.companyUsers.filter((x) =>
                x[columnName[0]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            case 2:
              this.list = this.company.data.companyUsers.filter((x) =>
                x[columnName[0]][columnName[1]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            case 3:
              this.list = this.company.data.companyUsers.filter((x) =>
                x[columnName[0]][columnName[1]][columnName[2]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            default:
              break;
          }
        }
      }
    },
    "company.data.companyUsers": function () {
      this.list = this.company.data.companyUsers;
    },
    "confirmSettigs.isConfirmed": function () {
      if (this.confirmSettigs.isConfirmed) {
        this.remove();
      }
    },
  },
  mounted() {
    this.searchColumns = this.headers.map((x) => ({
      id: x.value,
      name: x.text,
    }));

    this.selectedSearchColumn = this.searchColumns[0];

    this.list = this.company.data.companyUsers;
  },
};
</script>
<template>
  <div id="app">
    <div style="height: 100vh; max-width: 100vw">
      <ProgressBar :progress="progress" :brandId="brandId"></ProgressBar>
      <RocketVisual :brandName="brand" :brandId="brandId"></RocketVisual>
    </div>
  </div>
</template>
<script>
import RocketVisual from "./RocketVisual.vue";
import ProgressBar from "./ProgressBar.vue";
export default {
  components: {
    RocketVisual,
    ProgressBar,
  },
  data() {
    return {
      brand: "PR Brand",
      progress: 0,
      brandId: 0
    };
  },
  mounted(){
    this.brand = this.$route.params.brandName;
    this.progress = parseFloat(this.$route.params.progress);
    this.brandId = parseInt(this.$route.params.brandId, 10);
  }
};
</script>

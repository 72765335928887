export default function(instance){
    return{
        Login(payload){
            return instance.post('/Account/LogOn', payload);
        },
        GetLoginPassword(){
            return instance.get('/Account/GetLoginPassword');
        },
    }
}

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-if="networks != null"
          outlined
          dense
          v-model="selectedNetwork"
          :items="networks.data"
          item-text="fullname"
          item-value="id"
          label="Выберите пользователей"
          hide-details
          return-object
          class="rounded-0"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          v-if="selectedNetwork != null"
          :headers="headers"
          item-key="first_id"
          :items="selectedNetwork.userPharms"
          class="elevation-2 rounded-0"
          @click:row="selectPrPharm"
        >
          <template v-slot:item.selected="{ item }">
            <v-btn
              x-small
              fab
              text
              color="green"
              v-if="
                selectedNetworkPharm != null
                  ? item.id == selectedNetworkPharm.id
                  : false
              "
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="8">
            <v-text-field
              outlined
              dense
              label="Поиск"
              placeholder="Введите текст для поиска"
              v-model="search"
              hide-details
              class="rounded-0"
              prepend-icon="mdi-cloud-search-outline"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              v-model="selectedSearchColumn"
              :items="searchColumns"
              item-text="name"
              item-value="id"
              label="Столбец"
              hide-details
              return-object
              prepend-icon="mdi-format-list-bulleted-type"
              class="rounded-0"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card outlined class="mx-2" tile>
              <v-card-title class="py-2">
                <v-icon color="primary">mdi-plus-circle</v-icon>
                <span class="text-overline mx-2">Аптеки</span>
              </v-card-title>
              <v-divider />
              <v-data-table
                :headers="pharmacyHeaders"
                item-key="second_id"
                :items="list"
                class="elevation-2"
                @click:row="selectPhPharm"
              >
                <template v-slot:item.selected="{ item }">
                  <v-btn
                    x-small
                    fab
                    text
                    color="green"
                    v-if="
                      selectedPhPharm != null
                        ? item.id == selectedPhPharm.id
                        : false
                    "
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="green" outlined tile @click="apply()">Применить</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "UserBindings",
  computed: {
    ...mapGetters({
      getAllNetworks: "user/GET_ALL_NETWORKS",
      getPharms: "pharmacy/GET_PHARMACIES",
      getUserPharm: "userPharm/GET_USER_PHARM",
    }),
    networks: {
      get: function () {
        return this.getAllNetworks;
      },
      set: function (newVal) {
        this.setAllNetworks(newVal);
      },
    },
    userPharm: {
      get: function () {
        return this.getUserPharm;
      },
      set: function (newVal) {
        this.setUserPharm(newVal);
      },
    },
    pharmacies: {
      get: function () {
        return this.getPharms;
      },
    },
  },
  data: () => ({
    selectedNetwork: null,
    selectedNetworkPharm: null,
    selectedPhPharm: null,
    search: null,
    selectedSearchColumn: null,
    searchColumns: null,
    list: [],
    headers: [
      { text: "Наименование", align: "center", value: "fullname" },
      { text: "Город", align: "center", value: "city.name" },
      { text: "Адрес", align: "center", value: "address" },
      {
        text: "PharmcenterUserId",
        align: "center",
        value: "pharmcenterUserId",
      },
      {
        text: "Выбран?",
        align: "center",
        value: "selected",
      },
    ],
    pharmacyHeaders: [
      { text: "Логин", align: "center", value: "login" },
      { text: "Компания", align: "center", value: "companyName" },
      { text: "Наименование", align: "center", value: "name" },
      { text: "Телефон", align: "center", value: "phone" },
      { text: "Город", align: "center", value: "cityName" },
      { text: "Адрес", align: "center", value: "address" },
      { text: "БИН", align: "center", value: "bin" },
      {
        text: "Выбран?",
        align: "center",
        value: "selected",
      },
    ],
  }),
  methods: {
    ...mapActions({
      getAllNetworksFromAPI: "user/GET_ALL_NETWORKS_FROM_API",
      getPharmaciesFromAPI: "pharmacy/GET_PHARMACIES_FROM_API",
      updateUserPharmFromAPI: "userPharm/UPDATE_FROM_API",
    }),
    ...mapMutations({
      setAllNetworks: "user/SET_ALL_NETWORKS",
      setPharmacies: "pharmacy/SET_PHARMACIES",
      setUserPharm: "userPharm/SET_USER_PHARM",
      setSnackBar: "common/SET_SNACKBAR",
    }),
    selectPrPharm(value) {
      this.selectedNetworkPharm = value;
    },
    selectPhPharm(value) {
      this.selectedPhPharm = value;
    },
    async apply() {
      await this.updateUserPharmFromAPI({
        userPharmId: this.selectedNetworkPharm.id,
        pharmcenterUserId: this.selectedPhPharm.id,
      });
      if (this.userPharm.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Изменения успешно внесены!",
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при внесении изменений!",
        });
      }
    },
  },
  watch: {
    search: function () {
      if (this.search == null || this.search.length == 0) {
        this.list = this.pharmacies.data;
      } else {
        if (this.selectedSearchColumn != null) {
          let columnName = this.selectedSearchColumn.id;
          this.list = this.pharmacies.data.filter((x) =>
            x[columnName].toLowerCase().includes(this.search.toLowerCase())
          );
        }
      }
    },
  },
  async mounted() {
    await this.getAllNetworksFromAPI();

    this.searchColumns = this.pharmacyHeaders.map((x) => ({
      id: x.value,
      name: x.text,
    }));

    this.selectedSearchColumn = this.searchColumns[0];

    await this.getPharmaciesFromAPI();

    this.list = this.pharmacies.data;
  },
};
</script>
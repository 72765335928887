export default function(instance){
    return{
        GetByExcludeCompany(payload){
            return instance.get("/User/GetByExcludeCompany?companyId=" + payload.companyId);
        },
        Add(payload){
            return instance.put("/User/Add", payload);
        },
        GetAllNetworks(){
            return instance.get("/User/GetAllNetworks");
        },
        UpdateFullname(payload) {
            return instance.post("/User/UpdateFullname", payload);
        }
    }
}

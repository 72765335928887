import companyUserCompanyBrand from '../api/index'

const Commit = (query) => {
    return new Promise((resolve) => {
        resolve(companyUserCompanyBrand.companyUserCompanyBrand.Commit(query))
    })
}

export default {
    namespaced: true,
    state: {
        companyUserCompanyBrands: null,
    },
    getters: {
        GET_COMPANY_USER_COMPANY_BRANDS(state) {
            return state.companyUserCompanyBrands;
        },
    },
    mutations: {
        SET_COMPANY_USER_COMPANY_BRANDS(state, payload) {
            state.companyUserCompanyBrands = payload;
        },
    },
    actions: {
        async COMMIT_FROM_API({ commit }, payload) {
            await Commit(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_COMPANY_USER_COMPANY_BRANDS', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_COMPANY_USER_COMPANY_BRANDS', { succeeded: false });
            })
        },
    }
}

export default function(instance){
    return{
        GetAll(){
            return instance.get('/Company/GetAll');
        },
        GetById(payload){
            return instance.get('/Company/GetById?id=' + payload.id);
        },
        GetByCompanyAdmin(){
            return instance.get('/Company/GetByCompanyAdmin');
        },
        GetByMedicalRepresentative(){
            return instance.get('/Company/GetByMedicalRepresentative');
        },
        GetByRegionalManager(){
            return instance.get('/Company/GetByRegionalManager');
        },
        GetByPharmacy(){
            return instance.get('/Company/GetByPharmacy');
        },
        Update(payload){
            return instance.post("/Company/Update", payload);
        },
        Add(payload){
            return instance.put("/Company/Add", payload);
        }
    }
}

export default function(instance){
    return{
        AddRange(payload){
            return instance.put("/CompanyUser/AddRange", payload);
        },
        DeleteRange(payload){
            return instance.delete("/CompanyUser/DeleteRange", payload);
        },
        Update(payload){
            return instance.post("/CompanyUser/Update", payload);
        }
    }
}

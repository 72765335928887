<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Наименование плана"
          placeholder="Введите наименование плана"
          v-model="companyPlan.name"
          hide-details
          class="rounded-0"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table class="elevation-3 rounded-0">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="'hdr_' + index"
                  class="text-center"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in plans" :key="index">
                <td>{{ item.companyBrandName }}</td>
                <td>
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    v-model="item.quantity"
                    hide-details
                    class="rounded-0"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    v-model="item.amount"
                    hide-details
                    class="rounded-0"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    v-model="item.discount"
                    hide-details
                    class="rounded-0"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          outlined
          tile
          :color="company.data.companyColor.color1"
          @click="update()"
          >Редактировать</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "UpdatePeriod",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyPlan: "companyPlan/GET_COMPANY_PLAN",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    companyPlan: {
      get: function () {
        return this.getCompanyPlan;
      },
      set: function (newVal) {
        return this.setCompanyPlan(newVal);
      },
    },
  },
  data: () => ({
    dialogAction: dialogAction,
    headers: [
      { text: "Бренд" },
      { text: "Количество" },
      { text: "Сумма" },
      { text: "Скидка" },
    ],
    plans: null,
  }),
  methods: {
    ...mapActions({
      updateCompanyPlanFromApi: "companyPlan/UPDATE_COMPANY_PLAN_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setCompanyPlan: "companyPlan/SET_COMPANY_PLAN",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
    }),
    async update() {
      if (this.companyPlan.name != null && this.companyPlan.name.length > 3) {
        await this.updateCompanyPlanFromApi({
          id: this.companyPlan.id,
          name: this.companyPlan.name,
          list: this.plans.filter(x => x.quantity != 0 && x.amount != 0 && x.discount != 0).map(x => ({
            quantity: x.quantity,
            discount: x.discount,
            amount: x.amount,
            companyBrandId: x.companyBrandId
          }))
        });
        if (this.companyPlan.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "план успешно обновлен!",
          });
          this.setDialogSettings({
            show: false,
            title: "Модальное окно",
            color: this.company.data.companyColor.color1,
            persistent: true,
            maxWidth: 600,
            actionId: this.dialogAction.EMPTY,
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при обновлении плана!",
          });
        }
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Введите корректно данные!",
        });
      }
    },
    getPlans() {
      let companyPlanDetails = this.companyPlan.companyPlanDetails; 
      this.plans = this.company.data.companyBrands.filter(x => companyPlanDetails.map(z => z.companyBrandId).includes(x.id)).map((x) => ({
        companyBrandId: x.id,
        companyBrandName: x.brand.name,
        quantity: companyPlanDetails.find(z => z.companyBrandId == x.id) != null  ? companyPlanDetails.find(z => z.companyBrandId == x.id).quantity : 0,
        amount: companyPlanDetails.find(z => z.companyBrandId == x.id) != null  ? companyPlanDetails.find(z => z.companyBrandId == x.id).amount : 0,
        discount: companyPlanDetails.find(z => z.companyBrandId == x.id) != null  ? companyPlanDetails.find(z => z.companyBrandId == x.id).discount : 0
      }));
    },
  },
  watch: {
    companyPlan: function(){
      this.getPlans();
    }
  },
  mounted() {
    this.getPlans();
  },
};
</script>

<style lang="scss">
$mainColor: #e04b89;
$baseColor: #fff;

.wizard-progress {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: absolute;
    left: 0;
    top: 0;

    .step {
        display: table-cell;
        text-align: center;
        vertical-align: top;
        overflow: visible;
        position: relative;
        font-size: 12pt;
        color: $mainColor;
        font-family: "Russo One", sans-serif;
        padding-top: 95px;

        &:not(:last-child):before {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 52px;
            background-color: $baseColor;
            height: 6px;
            width: 100%;
        }

        .node {
            display: inline-block;
            border: 6px solid $baseColor;
            background-color: $baseColor;
            position: absolute;
            margin-left: -18px;
            border-radius: 50%;
            height: 60px;
            width: 60px;
            top: 25px;
            left: 46%;
            pointer-events: none;
            transition: all .1s ease-in-out;
        }

        .node:hover {
            transform: scale(1.1);
        }

        &.complete {
            &:before {
                background-color: $mainColor;
            }

            .node {
                border-color: $mainColor;
                pointer-events: all;
            }
        }

        &.mounted {
            .node {
                background: $mainColor;
            }

            .node:after {
                content: '✓';
                color: white;
                font-size: 20pt;
                font-weight: 900;
            }
        }

        &.in-progress {
            &:before {
                background: $mainColor;
                background: -moz-linear-gradient(left, $mainColor 0%, $baseColor 100%);
                background: -webkit-linear-gradient(left, $mainColor 0%, $baseColor 100%);
                background: linear-gradient(to right, $mainColor 0%, $baseColor 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$mainColor}', endColorstr='#{$baseColor}', GradientType=1);
            }

            .node {
                border-color: $mainColor;
                pointer-events: all;
            }
        }
    }
}

.btn {
    display: none;
    position: absolute;
    left: calc(50% - 80px);
    top: 150px;

    border: none;
    font-family: "Russo One", sans-serif;
    font-size: 12pt;
    color: white;
    background: #e04b89;
    cursor: pointer;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: 0 6px #b33d6e;
}

#plan_progress {
    position: absolute;
    font-family: "Russo One", sans-serif;
    font-size: 20pt;
    top: 150px;
    left: calc(50% - 120px);
    color: $mainColor;
}

.btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.btn {
    border-radius: 5px;
}

.btn:hover {
    box-shadow: 0 4px #b33d6e;
    top: 152px;
}

.btn:active {
    box-shadow: 0 0 #b33d6e;
    top: 154px;
}
</style>

<template>
    <div style="user-select: none">
        <div id="progress" class="wizard-progress">
            <div class="step">
                Корпус
                <div>20%</div>
                <button id="btn_body" class="node"></button>
            </div>
            <div class="step">
                Иллюминатор
                <div>40%</div>
                <button id="btn_porthole" class="node"></button>
            </div>
            <div class="step">
                Фюзеляж
                <div>60%</div>
                <button id="btn_wings" class="node"></button>
            </div>
            <div class="step">
                Обтекатель
                <div>80%</div>
                <button id="btn_fairing" class="node"></button>
            </div>
            <div class="step">
                Двигатель
                <div>100%</div>
                <button id="btn_engine" class="node"></button>
            </div>
        </div>
        <div id="plan_progress">
            Выполнение: {{ progress }}%
        </div>
        <button id="btn_launch" class="btn">
            Запустить!
        </button>
        <button id="btn_back" class="btn" style="left: calc(50% - 132px); top: 50px; display: none " @click="refresh()">
            Назад на космодром
        </button>
    </div>
</template>

<script>

export default {
    name: 'ProgressBar',
    props: {
        progress: {
            type: Number,
            required: true,
            default: 1
        },
        brandId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    methods: {
        initProgress() {
            var progressBar = document.getElementById('progress');
            var rocketProgress = localStorage.getItem('rocketProgress_' + this.brandId);

            if (rocketProgress && rocketProgress == 5) {
                var plan = document.getElementById('plan_progress');
                var btn = document.getElementById('btn_launch');

                plan.hidden = true;
                btn.style.opacity = 1;
                btn.style.display = 'block';
            }

            for (let index = 0; index < 5; index++) {
                progressBar.childNodes[index].classList.remove("in-progress");
                progressBar.childNodes[index].classList.remove("complete");
                progressBar.childNodes[index].classList.remove("mounted");
            }

            for (let index = 0; index < rocketProgress; index++) {
                progressBar.childNodes[index].classList.add("mounted");
            }

            if (this.progress >= 20) {
                progressBar.childNodes[0].classList.add("in-progress");
            }

            if (this.progress >= 40) {
                progressBar.childNodes[0].classList.remove("in-progress");
                progressBar.childNodes[0].classList.add("complete");
                progressBar.childNodes[1].classList.add("in-progress");
            }

            if (this.progress >= 60) {
                progressBar.childNodes[1].classList.remove("in-progress");
                progressBar.childNodes[1].classList.add("complete");
                progressBar.childNodes[2].classList.add("in-progress");
            }

            if (this.progress >= 80) {
                progressBar.childNodes[2].classList.remove("in-progress");
                progressBar.childNodes[2].classList.add("complete");
                progressBar.childNodes[3].classList.add("in-progress");
            }

            if (this.progress >= 100) {
                progressBar.childNodes[3].classList.remove("in-progress");
                progressBar.childNodes[3].classList.add("complete");
                progressBar.childNodes[4].classList.add("in-progress");
            }
        },
        refresh(){
            document.location.reload();
        }
    },
    mounted() {
        this.initProgress()
    },
    updated() {
        this.initProgress()
    }
}
</script>
<template>
  <div >
    <v-card elevation="0" tile height="calc(100vh - 96px)" width="100%" class="mx-1 py-2 d-flex justify-center align-center">
      <v-row>
        <v-col cols="12">
            <settings v-if="sideBarSettings.selectedMenuItemId == 1" />
            <users v-else-if="sideBarSettings.selectedMenuItemId == 2" />
            <reports v-else-if="sideBarSettings.selectedMenuItemId == 3" />
            <brands v-else-if="sideBarSettings.selectedMenuItemId == 5" />
            <periods v-else-if="sideBarSettings.selectedMenuItemId == 6" />
            <about v-else-if="sideBarSettings.selectedMenuItemId == 7" />
            <plans v-else-if="sideBarSettings.selectedMenuItemId == 9" />
            <how-to-connect v-else-if="sideBarSettings.selectedMenuItemId == 8" />
            <cache v-else-if="sideBarSettings.selectedMenuItemId == 10" />
            <div v-else></div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Users from "./Users/Users.vue";
import Settings from "./Settings/Settings.vue";
import Brands from "./Brands/Brands.vue";
import Periods from "./Periods/Periods.vue";
import Reports from "./Reports/Reports.vue";
import Plans from "./Plans/Plans.vue"
import About from "../About.vue"
import Cache from "./Cache.vue"
import HowToConnect from '../HowToConnect.vue';
export default {
  name: "Company",
  components: {
    Users,
    Settings,
    Brands,
    Periods,
    Reports,
    About,
    HowToConnect,
    Plans,
    Cache
  },
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanies: "company/GET_COMPANIES",
      getSideBarSettings: "common/GET_SIDEBAR_SETTINGS",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
      set: function (newValue) {
        this.setCompany(newValue);
      },
    },
    companies: {
      get: function () {
        return this.getCompanies;
      },
      set: function (newValue) {
        this.setCompanies(newValue);
      },
    },
    sideBarSettings: {
      get: function () {
        return this.getSideBarSettings;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getCompaniesFromAPI: "company/GET_COMPANIES_FROM_API",
      getCompanyByIdFromAPI: "company/GET_COMPANY_BY_ID_FROM_API",
    }),
    ...mapMutations({
      setAppBarSettings: "common/SET_APP_BAR_SETTINGS",
      setSideBarSettings: "common/SET_SIDEBAR_SETTINGS",
      setCompany: "company/SET_COMPANY",
      setCompanies: "company/SET_COMPANIES",
    }),
    async getCompanyMethod() {
      await this.getCompanyByIdFromAPI({
        id: this.sideBarSettings.selectedCompany.id,
      });
      this.setAppBarSettings({
        show: true,
        color: this.company.data.companyColor.color1,
      });
    },
  },
  watch: {
    "sideBarSettings.selectedCompany": function () {
      this.getCompanyMethod();
    },
  },
  async mounted() {
    this.setAppBarSettings({
      show: true,
      color: "primary",
    });

    await this.getCompaniesFromAPI();

    this.setSideBarSettings({
      clipped: true,
      drawer: true,
      color: "white",
      show: true,
      companySelectShow: true,
      selectedCompany: null,
      companies: this.companies,
      showCompanySelect: true,
      selectedMenuItemId: 1,
      items: [
        {
          id: 1,
          title: "Настройка",
          description: "Настройка компании",
          icon: "mdi-cog",
          externalUrl: null,
        },
        // {
        //   id: 5,
        //   title: "Бренды",
        //   description: "Добавление/удаление брендов",
        //   icon: "mdi-watermark",
        //   externalUrl: null,
        // },
        {
          id: 2,
          title: "Пользователи",
          description: "Управление пользователями",
          icon: "mdi-account-group",
          externalUrl: null,
        },
        {
          id: 6,
          title: "Периоды",
          description: "Настройка периодов",
          icon: "mdi-calendar-clock",
          externalUrl: null,
        },
        {
          id: 9,
          title: "Планы",
          description: "Настройка планов",
          icon: "mdi-timer-sand",
          externalUrl: null,
        },
        {
          id: 3,
          title: "Отчеты",
          description: "Формирование отчетов",
          icon: "mdi-chart-box",
          externalUrl: null,
        },
        {
          id: 4,
          title: "Документооборот",
          description: "Управление документами",
          icon: "mdi-text-box-multiple",
          externalUrl: null,
        },
        {
          id: 7,
          title: "О программе",
          description: "Информация о программе",
          icon: "mdi-information",
          externalUrl: null,
        },
        {
          id: 8,
          title: "Как подключиться?",
          description: "Информация о подключении",
          icon: "mdi-transit-connection-variant",
          externalUrl: null,
        },
        {
          id: 10,
          title: "Кэш",
          description: "Применить изменения",
          icon: "mdi-database-refresh",
          externalUrl: null,
        }
      ],
    });
  },
};
</script>
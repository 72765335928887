<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Наименование периода"
          placeholder="Введите наименование периода"
          v-model="name"
          hide-details
          class="rounded-0"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-menu
          ref="menuFrom"
          v-model="menuFrom"
          :close-on-content-click="false"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFrom"
              label="Дата начала"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              class="rounded-0"
              hide-details
              :color="company.data.companyColor.color1"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
            :color="company.data.companyColor.color1"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              :color="company.data.companyColor.color1"
              @click="$refs.menuFrom.save(dateFrom)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          ref="menuTo"
          v-model="menuTo"
          :close-on-content-click="false"
          :return-value.sync="dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTo"
              label="Дата начала"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              class="rounded-0"
              hide-details
              :color="company.data.companyColor.color1"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
            :color="company.data.companyColor.color1"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              :color="company.data.companyColor.color1"
              @click="$refs.menuTo.save(dateTo)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          outlined
          tile
          :color="company.data.companyColor.color1"
          @click="add()"
          >Добавить</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "AddPeriod",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyPeriod: "companyPeriod/GET_COMPANY_PERIOD",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
      set: function (newVal) {
        this.setCompany(newVal);
      },
    },
    companyPeriod: {
      get: function () {
        return this.getCompanyPeriod;
      },
      set: function (newVal) {
        this.setCompanyPeriod(newVal);
      },
    },
  },
  data: () => ({
    dialogAction: dialogAction,
    name: null,
    dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuFrom: false,
    dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuTo: false,
  }),
  methods: {
    ...mapActions({
      addCompanyPeriodFromApi: "companyPeriod/ADD_COMPANY_PERIOD_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setCompanyPeriod: "companyPeriod/SET_COMPANY_PERIOD",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setCompany: "company/SET_COMPANY",
    }),
    async add() {
      if (
        this.name != null &&
        this.name.length > 3 &&
        this.dateFrom != null &&
        this.dateTo != null
      ) {
        await this.addCompanyPeriodFromApi({
          companyId: this.company.data.id,
          name: this.name,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        });
        if (this.companyPeriod.succeeded) {
          //update company object in vuex store
          this.company.data.companyPeriods.push(this.companyPeriod.data);
          //

          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Период успешно добавлен!",
          });

          this.setDialogSettings({
            show: false,
            title: "Модальное окно",
            color: this.company.data.companyColor.color1,
            persistent: true,
            maxWidth: 600,
            actionId: this.dialogAction.EMPTY,
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при добавлении периода!",
          });
        }
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Введите корректно данные!",
        });
      }
    },
  },
};
</script>
<template>
  <div>
    <v-card
      elevation="0"
      tile
      height="calc(100vh - 96px)"
      width="100%"
      class="mx-1 py-2 d-flex justify-center align-center"
    >
      <v-row>
        <v-col cols="12">
          <plan v-if="sideBarSettings.selectedMenuItemId == 1"></plan>
          <reports
            v-else-if="sideBarSettings.selectedMenuItemId == 3"
          ></reports>
          <about v-else-if="sideBarSettings.selectedMenuItemId == 7" />
          <how-to-connect v-else-if="sideBarSettings.selectedMenuItemId == 8" />
          <div v-else></div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Plan from "./Plan.vue";
import Reports from "./Reports.vue";
import About from "../About.vue"
import HowToConnect from "../HowToConnect.vue"
export default {
  name: "Pharmacy",
  components: {
    Plan,
    Reports,
    About,
    HowToConnect
  },
  computed: {
    ...mapGetters({
      getPharmacyCompanies: "company/GET_COMPANIES",
      getSideBarSettings: "common/GET_SIDEBAR_SETTINGS",
    }),
    companies: {
      get: function () {
        return this.getPharmacyCompanies;
      },
    },
    sideBarSettings: {
      get: function () {
        return this.getSideBarSettings;
      },
    },
  },
  data: () => ({
    loading: false,
    company: null,
  }),
  methods: {
    ...mapActions({
      getPharmacyCompaniesFromApi: "company/GET_COMPANY_BY_PHARMACY_FROM_API",
    }),
    ...mapMutations({
      setAppBarSettings: "common/SET_APP_BAR_SETTINGS",
      setSideBarSettings: "common/SET_SIDEBAR_SETTINGS",
      setCompany: "company/SET_COMPANY",
    }),
  },
  watch: {
    "sideBarSettings.selectedCompany": function () {
      this.setAppBarSettings({
        show: true,
        color: this.sideBarSettings.selectedCompany.companyColor.color1,
      });
      this.setCompany(this.sideBarSettings.selectedCompany);
    },
  },
  async mounted() {
    this.setAppBarSettings({
      show: true,
      color: "primary",
    });
    this.loading = true;
    await this.getPharmacyCompaniesFromApi();
    this.loading = false;

    this.setSideBarSettings({
      clipped: true,
      drawer: true,
      color: "white",
      show: true,
      companySelectShow: true,
      selectedCompany: null,
      companies: this.companies,
      selectedMenuItemId: 1,
      showCompanySelect: true,
      items: [
        {
          id: 1,
          title: "Выполнение плана",
          description: "Просмотр выполнения плана",
          icon: "mdi-progress-pencil",
          externalUrl: null,
        },
        {
          id: 3,
          title: "Отчеты",
          description: "Формирование отчетов",
          icon: "mdi-chart-box",
          externalUrl: null,
        },
        {
          id: 7,
          title: "О программе",
          description: "Информация о программе",
          icon: "mdi-information",
          externalUrl: null,
        },
        {
          id: 8,
          title: "Как подключиться?",
          description: "Информация о подключении",
          icon: "mdi-transit-connection-variant",
          externalUrl: null,
        }
      ],
    });
  },
};
</script>
<template>
  <v-app>
    <v-navigation-drawer :clipped="sideBarSettings.clipped" v-model="sideBarSettings.drawer" app light
      :color="sideBarSettings.color" v-if="sideBarSettings.show">
      <v-autocomplete v-if="sideBarSettings.showCompanySelect" outlined dense v-model="sideBarSettings.selectedCompany"
        :items="sideBarSettings.companies.data" item-text="name" item-value="id" label="Выберите компанию" hide-details
        return-object class="mx-2 my-3 rounded-0">
      </v-autocomplete>
      <v-img :src="sideBarSettings.selectedCompany.logo" height="200px" width="100%" contain
        v-if="sideBarSettings.selectedCompany != null"></v-img>
      <v-divider class="my-2" />
      <v-list dense>
        <v-list-item-group v-model="sideBarSettings.selectedMenuItemId">
          <v-list-item v-for="item in sideBarSettings.items" :key="'menuItem_' + item.id" v-bind:value="item.id">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app :color="appBarSettings.color" class="black--text" v-if="appBarSettings.show"
      :clipped-left="sideBarSettings.clipped">
      <v-app-bar-nav-icon @click.stop="setDrawer()" v-if="sideBarSettings.show">
        <v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="loginInfo != null">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="white-text" v-bind="attrs" v-on="on">
            <v-icon color="white" left>mdi-account</v-icon>
            <span class="white--text text-capitalize">{{
              loginInfo.data.login == undefined ? "" : loginInfo.data.login
              }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <img
                src="https://img2.pngio.com/profile-male-persona-profile-male-user-avatar-svg-png-icon-free-user-avatar-png-981_878.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                loginInfo.data == null ? "" : loginInfo.data.fullName
                }}</v-list-item-title>
              <v-list-item-subtitle>{{
                loginInfo.data == null ? "" : loginInfo.data.roleName
                }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group>
            <v-list-item @click="logOut()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Выход</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="d-flex justify-center align-center">
      <router-view></router-view>
      <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" bottom :color="snackbar.color">
        {{ snackbar.text }}
      </v-snackbar>
      <v-dialog v-if="dialogSettings != null" :max-width="dialogSettings.maxWidth" v-model="dialogSettings.show"
        :persistent="dialogSettings.persistent">
        <v-card>
          <v-toolbar :color="dialogSettings.color" dark>
            <v-toolbar-title>{{ dialogSettings.title }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="dialogSettings.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="text-center px-2 py-2">
            <add-user v-if="dialogSettings.actionId == dialogAction.ADD_USER" />
            <add-company v-if="dialogSettings.actionId == dialogAction.ADD_COMPANY" />
            <control-user-brands v-if="dialogSettings.actionId == dialogAction.CONTROL_USER_BRANDS" />
            <control-user-brand-plans v-if="
                dialogSettings.actionId == dialogAction.CONTROL_USER_BRAND_PLANS
              " />
            <brand-skus v-else-if="dialogSettings.actionId == dialogAction.BRAND_SKUS" />
            <add-period v-else-if="dialogSettings.actionId == dialogAction.ADD_PERIOD" />
            <update-period v-else-if="dialogSettings.actionId == dialogAction.UPDATE_PERIOD" />
            <add-plan v-else-if="dialogSettings.actionId == dialogAction.ADD_PLAN" />
            <update-plan v-else-if="dialogSettings.actionId == dialogAction.UPDATE_PLAN" />
            <pharm-brand-plans v-else-if="
                dialogSettings.actionId == dialogAction.PHARM_BRAND_PLANS
              " />
            <pharm-plans v-else-if="dialogSettings.actionId == dialogAction.PHARM_PLANS" />
            <control-relation v-else-if="
                dialogSettings.actionId == dialogAction.CONTROL_RELATION
              " />
            <user-pharms v-else-if="dialogSettings.actionId == dialogAction.USER_PHARMS" />
            <update-user v-else-if="dialogSettings.actionId == dialogAction.UPDATE_USER">
            </update-user>
            <edit-user-pharms v-else-if="
                dialogSettings.actionId == dialogAction.EDIT_USER_PHARMS
              " />
            <user-bindings v-else-if="dialogSettings.actionId == dialogAction.ADD_BINDINGS" />

            <confirm v-else-if="dialogSettings.actionId == dialogAction.CONFIRM" />
            <div v-if="dialogSettings.actionId == dialogAction.EMPTY"></div>
          </v-card-text>
          <v-card-actions class="justify-end" v-if="dialogSettings.closeButton">
            <v-btn text @click="dialogSettings.show = false">Закрыть</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { dialogAction } from "./enums/dialogAction";
import AddUser from "./components/Administrator/Users/AddUser.vue";
import ControlUserBrands from "./components/Administrator/Users/ControlUserBrands.vue";
import ControlUserBrandPlans from "./components/Administrator/Users/ControlUserBrandPlans.vue";
import AddCompany from "./components/Administrator/AddCompany.vue";
import BrandSkus from "./components/Administrator/Brands/BrandSkus.vue";
import AddPeriod from "./components/Administrator/Periods/AddPeriod.vue";
import UpdatePeriod from "./components/Administrator/Periods/UpdatePeriod.vue";
import PharmPlans from "./components/Producer/PharmPlans.vue";
import ControlRelation from "./components/Administrator/Users/ControlRelation.vue";
import UserPharms from "./components/Administrator/Users/UserPharms.vue";
import EditUserPharms from "./components/Administrator/Users/EditUserPharms.vue";
import UserBindings from "./components/Administrator/Users/UserBindings.vue";
import AddPlan from "./components/Administrator/Plans/AddPlan.vue";
import UpdatePlan from "./components/Administrator/Plans/UpdatePlan.vue";
import UpdateUser from "./components/Administrator/Users/UpdateUser.vue";
import Confirm from "./components/Confirm.vue";
export default {
  name: "App",
  components: {
    AddUser,
    AddCompany,
    ControlUserBrands,
    ControlUserBrandPlans,
    BrandSkus,
    AddPeriod,
    UpdatePeriod,
    PharmPlans,
    ControlRelation,
    UserPharms,
    EditUserPharms,
    UserBindings,
    AddPlan,
    UpdatePlan,
    UpdateUser,
    Confirm,
  },
  computed: {
    ...mapGetters({
      getAppBarSettings: "common/GET_APP_BAR_SETTINGS",
      getSnackbar: "common/GET_SNACKBAR",
      getDialogSettings: "common/GET_DIALOG_SETTINGS",
      getLoginInfo: "account/GET_LOGIN_INFO",
      getSideBarSettings: "common/GET_SIDEBAR_SETTINGS",
      getLoginPasword: "account/GET_LOGIN_PASSWORD",
    }),
    appBarSettings: {
      get: function () {
        return this.getAppBarSettings;
      },
      set: function (newValue) {
        this.setAppBarSettings(newValue);
      },
    },
    dialogSettings: {
      get: function () {
        return this.getDialogSettings;
      },
      set: function (newValue) {
        this.setDialogSettings(newValue);
      },
    },
    snackbar: {
      get: function () {
        return this.getSnackbar;
      },
      set: function (newValue) {
        this.setSnackBar(newValue);
      },
    },
    sideBarSettings: {
      get: function () {
        return this.getSideBarSettings;
      },
      set: function (newValue) {
        this.setSideBarSettings(newValue);
      },
    },
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
      set: function (newValue) {
        this.setLoginInfo(newValue);
      },
    },
    loginPassword: {
      get: function () {
        return this.getLoginPasword;
      },
      set: function (newValue) {
        this.setLoginPassword(newValue);
      },
    },
  },
  data: () => ({
    dialog: true,
    dialogAction: dialogAction,
  }),
  methods: {
    ...mapActions({
      commitAppBarSettings: "common/COMMIT_APP_BAR_SETTINGS",
      commitDialogSettings: "common/COMMIT_DIALOG_SETTINGS",
      commitSideBarSettings: "common/COMMIT_SIDEBAR_SETTINGS",
      generateSnackbar: "common/GENERATE_SNACKBAR",
      getLoginPasswordFromAPI: "account/GET_LOGIN_PASSWORD_FROM_API",
    }),
    ...mapMutations({
      setAppBarSettings: "common/SET_APP_BAR_SETTINGS",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setSideBarSettings: "common/SET_SIDEBAR_SETTINGS",
      setSnackBar: "common/SET_SNACKBAR",
      setLoginInfo: "account/SET_LOGIN_INFO",
      setLoginPassword: "account/SET_LOGIN_PASSWORD",
    }),
    logOut() {
      this.loginInfo = { data: null, succeeded: false };
      localStorage.removeItem("identity");
      this.$router.push({
        path: "/account/login",
      });

      this.commitSideBarSettings({
        clipped: true,
        drawer: false,
        color: "white",
        show: false,
        companySelectShow: false,
        selectedCompany: null,
        selectedMenuItemId: null,
        showCompanySelect: false,
        companies: null,
        items: [],
      });
    },
    setDrawer() {
      this.sideBarSettings.drawer = !this.sideBarSettings.drawer;
    },
    async routeToPM() {
      await this.getLoginPasswordFromAPI();
      if (this.loginPassword.succeeded) {
        let url =
          "https://std.provisor.kz/Account/ReLogOn?UserName=" +
          this.loginPassword.data.login +
          "&password=" +
          this.loginPassword.data.passwordDecrypted;
        window.open(url, "_blank");
      }
    },
  },
  watch: {
    "sideBarSettings.selectedMenuItemId": function () {
      let item = this.sideBarSettings.items.find(
        (x) => x.id == this.sideBarSettings.selectedMenuItemId
      );
      if (item != undefined) {
        if (item.externalUrl != null) {
          this.routeToPM();
        }
      }
    },
  },
  created() {
    this.commitAppBarSettings({
      show: false,
      color: "primary",
    });

    this.commitSideBarSettings({
      clipped: true,
      drawer: false,
      color: "white",
      show: false,
      companySelectShow: false,
      selectedCompany: null,
      selectedMenuItemId: null,
      showCompanySelect: false,
      companies: null,
      items: [],
    });

    this.commitDialogSettings({
      show: false,
      title: "Модальное окно",
      color: "primary",
      persistent: true,
      maxWidth: 600,
      actionId: this.dialogAction.EMPTY,
      closeButton: true
    });
  },
};
</script>
import brand from '../api/index'

const GetAll = () => {
    return new Promise((resolve) => {
        resolve(brand.brand.GetAll())
    })
}


export default {
    namespaced: true,
    state: {
        brands: [],
        selectedBrand: null
    },
    getters: {
        GET_BRANDS(state) {
            return state.brands;
        },
        GET_SELECTED_BRAND(state){
            return state.selectedBrand;
        }
    },
    mutations: {
        SET_BRANDS(state, payload) {
            state.brands = payload;
        },
        SET_SELECTED_BRAND(state, payload) {
            state.selectedBrand = payload;
        },
    },
    actions: {
        async GET_BRANDS_FROM_API({ commit }) {
            await GetAll().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_BRANDS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_BRANDS', { succeeded: false });
                })
        },
        COMMIT_SELECTED_BRAND({commit}, payload){
            commit('SET_SELECTED_BRAND', payload);
        }
    }
}
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          outlined
          dense
          :color="company.data.companyColor.color1"
          v-model="selectedPeriod"
          :items="company.data.companyPeriods"
          item-text="name"
          item-value="id"
          label="Выберите период"
          hide-details
          return-object
          class="rounded-0"
        >
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-icon :color="company.data.companyColor.color1"
                >mdi-calendar-clock</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle
                >{{ new Date(data.item.dateFrom).toLocaleDateString() }} -
                {{
                  new Date(data.item.dateTo).toLocaleDateString()
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          outlined
          dense
          :color="company.data.companyColor.color1"
          v-model="selectedPlans"
          :items="company.data.companyPlans"
          item-text="name"
          item-value="id"
          label="Выберите план"
          hide-details
          return-object
          class="rounded-0"
          multiple
          small-chips
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          outlined
          :color="company.data.companyColor.color1"
          tile
          @click="apply()"
          >Применить</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ControlUserBrandPlans",
  data: () => ({
    selectedPeriod: null,
    selectedPlans: [],
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyUser: "companyUser/GET_COMPANY_USER",
      getCompanyUserPeriodPlan:
        "companyUserPeriodPlan/GET_COMPANY_USER_PERIOD_PLAN",
    }),
    companyUser: {
      get: function () {
        return this.getCompanyUser;
      },
    },
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    companyUserPeriodPlan: {
      get: function () {
        return this.getCompanyUserPeriodPlan;
      },
    },
  },
  methods: {
    ...mapActions({
      commitCompanyUserPeriodPlanFromApi:
        "companyUserPeriodPlan/COMMIT_COMPANY_USER_PERIOD_PLAN_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
    }),
    async apply() {
      if (this.selectedPeriod != null && this.selectedPlans.length > 0) {
        let companyUserId = this.companyUser.id;
        let companyPeriodId = this.selectedPeriod.id;
        let list = this.selectedPlans.map((x) => ({
          companyUserId: companyUserId,
          companyPeriodId: companyPeriodId,
          companyPlanId: x.id,
        }));
        await this.commitCompanyUserPeriodPlanFromApi({
          list: list,
        });
        if (this.companyUserPeriodPlan.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Планы успешно привязаны!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при привязке планов!",
          });
        }
      }
      else{
        this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при привязке планов!",
          });
        }
    },
  },
  watch: {
    selectedPeriod: function () {
      let companyPlanIds = this.company.data.companyUserPeriodPlans.filter(x => x.companyPeriodId == this.selectedPeriod.id && x.companyUserId == this.companyUser.id).map(x => x.companyPlanId);
      this.selectedPlans = this.company.data.companyPlans.filter(x => companyPlanIds.includes(x.id));
    },
    companyUser: function () {
      let companyPlanIds = this.company.data.companyUserPeriodPlans.filter(x => x.companyPeriodId == this.selectedPeriod.id && x.companyUserId == this.companyUser.id).map(x => x.companyPlanId);
      this.selectedPlans = this.company.data.companyPlans.filter(x => companyPlanIds.includes(x.id));
    }
  },
};
</script>
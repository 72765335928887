<template>
  <div v-if="company != null">
    <v-row>
      <v-col cols="12">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="4" lg="4" xl="4" class="py-0">
            <v-autocomplete
              v-if="company != null"
              outlined
              dense
              v-model="selectedPeriod"
              :items="company.companyPeriods"
              item-text="name"
              item-value="id"
              label="Выберите период"
              hide-details
              return-object
              class="mx-2 my-2 rounded-0"
            >
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-icon :color="company.companyColor.color1"
                    >mdi-calendar-clock</v-icon
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    >{{ new Date(data.item.dateFrom).toLocaleDateString() }} -
                    {{
                      new Date(data.item.dateTo).toLocaleDateString()
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="4" lg="4" xl="4" class="py-0 text-center">
            <div>
              <span class="text-overline">Текущая дата: </span
              ><span
                class="text-overline"
                :style="'color:' + company.companyColor.color1"
                >{{ new Date().toLocaleDateString() }}</span
              >
            </div>
            <div v-if="selectedPeriod != null">
              <span class="text-overline">Осталось недель: </span
              ><span
                class="text-overline"
                :style="'color:' + company.companyColor.color1"
                >{{
                  plans.data != null && plans.data.length > 0 ? plans.data[0].weekRemain : "н/д"
                }}</span
              >
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="py-0">
            <v-card
              outlined
              class="mx-3"
              tile
              :color="company.companyColor.color1"
            >
              <v-card-title class="py-2">
                <v-icon :color="company.companyColor.color3"
                  >mdi-progress-pencil</v-icon
                >
                <span
                  class="text-overline mx-2"
                  :style="'color: ' + company.companyColor.color3"
                  >Выполнение плана</span
                >
              </v-card-title>
              <v-divider />
              <v-tabs
                grow
                v-if="companyPlans != null"
                :color="company.companyColor.color1"
                v-model="selectedPharmPlan"
              >
                <v-tab
                  v-for="planItem in companyPlans"
                  :key="planItem.id"
                  @click="showPlan(planItem.id)"
                  >{{ planItem.name }}</v-tab
                >
              </v-tabs>
              <v-data-table
                v-if="list != null"
                :headers="headers"
                :items="list"
                class="elevation-0"
                loading-text="Идет загрузка..."
                :loading="loading"
                disable-pagination
                hide-default-footer
                fixed-header
                height="50vh"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
                @item-expanded="onExpand"
                item-key="brandId"
                @click:row="openVisualization"
              >
                <v-progress-linear
                  v-show="loading"
                  slot="progress"
                  :color="company.companyColor.color1"
                  indeterminate
                ></v-progress-linear>
                <template v-slot:item.planPack="{ item }">
                  {{ item.planPack.toLocaleString() }}
                </template>
                <template v-slot:item.purchasePackFact="{ item }">
                  {{ item.purchasePackFact.toLocaleString() }}
                </template>
                <template v-slot:item.purchaseSumFact="{ item }">
                  {{ item.purchaseSumFact.toLocaleString() }}
                </template>
                <template v-slot:item.planRemain="{ item }">
                  <span v-if="item.packRemains <= 0"
                    ><v-chip class="green white--text"
                      >0 \ {{ item.planDone }} %</v-chip
                    ></span
                  >
                  <span v-else
                    >{{ item.packRemains.toLocaleString() }} \
                    {{ item.planDone }}%</span
                  >
                </template>
                <template v-slot:item.weekPlanPack="{ item }">
                  <span v-if="item.weekPlanPack < 0">0</span>
                  <span v-else>{{ item.weekPlanPack.toLocaleString() }}</span>
                </template>
                <template v-slot:item.sumPlan="{ item }">
                  {{ item.sumPlan.toLocaleString() }}
                </template>
                <template v-slot:item.sumFact="{ item }">
                  <span v-if="item.planDone >= 100">
                    {{ item.sumFact.toLocaleString() }}
                  </span>
                  <span v-else>
                    <v-chip class="orange white--text">
                      <v-icon>mdi-progress-clock</v-icon>
                    </v-chip>
                  </span>
                </template>
                <template v-slot:expanded-item="{ headers }">
                  <td :colspan="headers.length" class="px-2 py-2">
                    <v-skeleton-loader
                      type="table"
                      v-if="skuPlans.data == null"
                    >
                    </v-skeleton-loader>
                    <v-simple-table v-else>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">SKU</th>
                            <th class="text-center">Факт закупа (шт.)</th>
                            <th class="text-center">Факт закупа (₸)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="skuPlan in skuPlans.data"
                            :key="skuPlan.name"
                          >
                            <td class="text-center">{{ skuPlan.skuName }}</td>
                            <td class="text-center">
                              {{ skuPlan.packFact.toLocaleString() }}
                            </td>
                            <td class="text-center">
                              {{ skuPlan.sumFact.toLocaleString() }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          class="d-flex justify-center align-center mx-3"
          style="height: 10vh"
          tile
          outlined
        >
          <v-card-text class="text-center">
            <v-btn
              v-if="selectedBrand != null"
              :color="company.companyColor.color1"
              outlined
              @click="openVisualizationLink()"
              >Открыть визуализацию</v-btn
            >
            <div v-else>
              <v-icon x-large color="red">mdi-cloud-alert</v-icon>
              <div class="text-subtitle-2">Выберите бренд</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Plan",
  components: {},
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getPlans: "calculate/GET_PLANS",
      getSkuPlans: "calculate/GET_SKU_PLANS",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    plans: {
      get: function () {
        return this.getPlans;
      },
      set: function (newVal) {
        this.setPlans(newVal);
      },
    },
    skuPlans: {
      get: function () {
        return this.getSkuPlans;
      },
      set: function (newVal) {
        this.setSkuPlans(newVal);
      },
    },
  },
  data: () => ({
    selectedBrand: null,
    expanded: [],
    singleExpand: true,
    selectedPeriod: null,
    companyPlans: null,
    selectedPharmPlan: null,
    list: [],
    loading: false,
    headers: [
      {
        text: "Наименование бренда",
        align: "center",
        value: "brandName",
        sortable: false,
      },
      {
        text: "План закупа (шт.)",
        align: "center",
        value: "planPack",
        sortable: false,
      },
      {
        text: "Факт закупа (шт.)",
        align: "center",
        value: "purchasePackFact",
        sortable: false,
      },
      {
        text: "Факт закупа (₸)",
        align: "center",
        value: "purchaseSumFact",
        sortable: false,
      },
      {
        text: "Осталось (шт.) \\ Выполнено (%)",
        align: "center",
        value: "planRemain",
        sortable: false,
      },
      {
        text: "План закупа на след. неделю (шт.)",
        align: "center",
        value: "weekPlanPack",
        sortable: false,
      },
      {
        text: "Бонус-план (₸)",
        align: "center",
        value: "sumPlan",
        sortable: false,
      },
      {
        text: "Бонус-факт (₸)",
        align: "center",
        value: "sumFact",
        sortable: false,
      },
    ],
  }),
  methods: {
    ...mapActions({
      getPlansFromApi: "calculate/GET_PLANS_FROM_API",
      getSkuPlansFromApi: "calculate/GET_SKU_PLANS_FROM_API",
    }),
    ...mapMutations({
      setPlans: "calculate/SET_PLANS",
      setSkuPlans: "calculate/SET_SKU_PLANS",
    }),
    async getPlansMethod() {
      this.loading = true;
      await this.getPlansFromApi({ periodId: this.selectedPeriod.id });
      this.selectedPharmPlan = null;
      if (this.plans != null && this.plans.succeeded) {
        this.getCompanyPlans();
        this.selectedPharmPlan = this.companyPlans[0]?.id;
        this.list = this.plans.data.filter(
          (x) => x.companyPlanId == this.companyPlans[0].id
        );
      }
      this.loading = false;
    },
    getCompanyPlans() {
      let companyPlans = [];
      this.plans.data
        .map((x) => ({ id: x.companyPlanId, name: x.companyPlanName, levelNum: x.companyPlanLevelNum }))
        .filter(function (item) {
          var i = companyPlans.findIndex(
            (x) => x.name == item.name && x.id == item.id && x.levelNum == item.levelNum
          );
          if (i <= -1) {
            companyPlans.push(item);
          }
          return null;
        });

      this.companyPlans = companyPlans.sort(function (a, b) {
        return a.levelNum - b.levelNum;
      });
    },
    showPlan(companyPlanId) {
      this.list = this.plans.data.filter(
        (x) => x.companyPlanId == companyPlanId
      );
    },
    openVisualization(value) {
      this.selectedBrand = {
        id: value.brandId,
        name: value.brandName,
        progress: value.planDone,
      };
    },
    async onExpand({ item, value }) {
      if (value) {
        this.skuPlans = { data: null, succeeded: false };
        await this.getSkuPlansFromApi({
          brandId: item.brandId,
          periodId: item.companyPeriodId,
          companyId: this.company.id,
        });
      }
    },
    openVisualizationLink() {
      var staticUrlPart =
        window.location.protocol +
        "//" +
        window.location.host +
        "/visualisation";
      window.open(
        staticUrlPart +
          "/" +
          this.selectedBrand.name +
          "/" +
          this.selectedBrand.progress +
          "/" +
          this.selectedBrand.id,
        "_blank"
      );
    },
  },
  watch: {
    selectedPeriod: function () {
      this.getPlansMethod();
    },
  },
};
</script>
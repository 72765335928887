<template>
  <v-container>
    <v-row>
      <v-col>
         <v-autocomplete
          outlined
          dense
          :color="company.data.companyColor.color1"
          v-model="selectedParentUser"
          :items="company.data.companyUsers.filter(x => x.user.roleId == (companyUser.user.roleId - 1))"
          item-text="user.login"
          item-value="user.id"
          label="Выберите пользователя-родителя"
          hide-details
          return-object
          class="rounded-0"
          :item-color="company.data.companyColor.color1"
        >
         </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn outlined :color="company.data.companyColor.color1" tile @click="apply()">Применить</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ControlUserBrands",
  data: () => ({
      selectedParentUser: null
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyUser: "companyUser/GET_COMPANY_USER",
      getUpdatedCompanyUser: "companyUser/GET_UPDATED_COMPANY_USER",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    companyUser: {
      get: function () {
        return this.getCompanyUser;
      },
      set: function (newVal) {
        return this.setCompanyUser(newVal);
      }
    },
    updatedCompanyUser: {
      get: function () {
        return this.getUpdatedCompanyUser;
      },
    },
  },
  methods: {
    ...mapActions({
      updateFromApi: "companyUser/UPDATE_FROM_API"
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setCompanyUser: "companyUser/SET_COMPANY_USER"
    }),
    initialize(){
        this.selectedParentUser = this.company.data.companyUsers.find(x => x.user.id == this.companyUser.parentUserId);
    },
    async apply(){
      await this.updateFromApi({
          id: this.companyUser.id,
          companyId: this.companyUser.companyId,
          userId: this.companyUser.userId,
          parentUserId: this.selectedParentUser.user.id
      });
      if (this.updatedCompanyUser.succeeded) {
          this.companyUser.parentUserId = this.updatedCompanyUser.data.parentUserId;
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Изменения успешно внесены!",
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при внесении изменений!",
          });
        }
    }
  },
  watch: {
      companyUser: function(){
         this.initialize();
      }
  },
  mounted(){
        this.initialize();   
  }
};
</script>
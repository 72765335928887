import companyUserPeriodPlan from '../api/index'

const Commit = (query) => {
    return new Promise((resolve) => {
        resolve(companyUserPeriodPlan.companyUserPeriodPlan.Commit(query))
    })
}

export default {
    namespaced: true,
    state: {
        companyUserPeriodPlan: null,
    },
    getters: {
        GET_COMPANY_USER_PERIOD_PLAN(state) {
            return state.companyUserPeriodPlan;
        },
    },
    mutations: {
        SET_COMPANY_USER_PERIOD_PLAN(state, payload) {
            state.companyUserPeriodPlan = payload;
        },
    },
    actions: {
        async COMMIT_COMPANY_USER_PERIOD_PLAN_FROM_API({ commit }, payload) {
            await Commit(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_COMPANY_USER_PERIOD_PLAN', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_COMPANY_USER_PERIOD_PLAN', { succeeded: false });
            })
        },
    }
}

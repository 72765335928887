<template>
  <div>
    <v-card
      outlined
      class="mx-3"
      tile
      :color="company.data.companyColor.color1"
    >
      <v-card-title class="py-2">
        <v-icon :color="company.data.companyColor.color3">mdi-chart-box</v-icon>
        <span
          class="text-overline mx-2"
          :style="'color: ' + company.data.companyColor.color3"
          >Отчеты</span
        >
      </v-card-title>
      <v-row class="white mx-1 my-1">
        <v-col cols="12">
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-autocomplete
                outlined
                dense
                :color="company.data.companyColor.color1"
                v-model="selectedReportType"
                :items="reportTypes"
                item-text="name"
                item-value="id"
                label="Тип отчета"
                hide-details
                return-object
                prepend-icon="mdi-format-list-bulleted-type"
                class="rounded-0"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-autocomplete
                outlined
                dense
                :color="company.data.companyColor.color1"
                :items="
                  company.data.companyBrands.filter(
                    (x) => x.companyPeriodId == selectedPeriod.id
                  )
                "
                v-model="selectedBrands"
                item-text="brand.name"
                item-value="brand.id"
                label="Бренды"
                hide-details
                return-object
                prepend-icon="mdi-watermark"
                multiple
                class="rounded-0"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    small
                    v-if="index === 0 || index === 1"
                    :color="company.data.companyColor.color1"
                    outlined
                  >
                    <span>{{ item.brand.name }}</span>
                  </v-chip>

                  <span v-if="index === 2" class="grey--text caption"
                    >(+{{ selectedBrands.length - 2 }} др.)</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-autocomplete
                outlined
                dense
                :color="company.data.companyColor.color1"
                :items="
                  company.data.companyUsers.filter((x) => x.user.roleId == 5)
                "
                v-model="selectedUsers"
                item-text="user.fullname"
                item-value="user.id"
                label="Аптеки"
                hide-details
                return-object
                prepend-icon="mdi-mortar-pestle-plus"
                small-chips
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    small
                    v-if="index === 0 || index === 1"
                    :color="company.data.companyColor.color1"
                    outlined
                  >
                    <span>{{ item.user.fullname }}</span>
                  </v-chip>

                  <span v-if="index === 2" class="grey--text caption"
                    >(+{{ selectedUsers.length - 2 }} др.)</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-autocomplete
                outlined
                dense
                :color="company.data.companyColor.color1"
                v-model="selectedPeriod"
                :items="company.data.companyPeriods"
                item-text="name"
                item-value="id"
                label="Выберите период"
                hide-details
                return-object
                class="rounded-0"
                prepend-icon="mdi-calendar-clock"
              >
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-icon :color="company.data.companyColor.color1"
                      >mdi-calendar-clock</v-icon
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      >{{ new Date(data.item.dateFrom).toLocaleDateString() }} -
                      {{
                        new Date(data.item.dateTo).toLocaleDateString()
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                tile
                outlined
                :color="company.data.companyColor.color1"
                @click="generate()"
                :loading="loading"
                :disabled="loading"
                >Сформировать</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import download from "../../api/index";
export default {
  name: "Select",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getReport: "report/GET_REPORT",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    report: {
      get: function () {
        return this.getReport;
      },
      set: function (newVal) {
        this.setReport(newVal);
      },
    },
  },
  data: () => ({
    loading: false,
    selectedReportType: { id: 2, name: "Отчет по обратным накладным" },
    selectedBrands: [],
    selectedUsers: [],
    selectedPeriod: null,
    reportTypes: [
      { id: 2, name: "Отчет по обратным накладным" },
      { id: 3, name: "Отчет компенсации" },
      { id: 5, name: "Промежуточный отчет" },
    ],
  }),
  methods: {
    ...mapActions({
      getInvoiceFromApi: "report/GET_PRODUCER_INVOICE_FROM_API",
      getCompensationFromApi: "report/GET_PRODUCER_COMPENSATION_FROM_API",
      getIntermediateFromApi: "report/GET_PRODUCER_INTERMEDIATE_FROM_API",
    }),
    ...mapMutations({
      setReport: "report/SET_REPORT",
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async generate() {
      if (
        this.selectedBrands.length > 0 &&
        this.selectedUsers.length > 0 &&
        this.selectedPeriod != null
      ) {
        this.loading = true;
        await this.reportCalculate();
        if (this.report.succeeded) {
          download.report.Download({ fileId: this.report.data });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при формировании отчета!",
          });
        }
        this.loading = false;
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Выберите бренды и аптеки!",
        });
      }
    },
    async reportCalculate() {
      let currentReportTypeId = this.selectedReportType.id;
      let reportParams = {
        from: this.selectedPeriod.dateFrom,
        to: this.selectedPeriod.dateTo,
        brandList: this.selectedBrands.map((x) => ({ id: x.brand.id })),
        userList: this.selectedUsers.map((x) => ({ id: x.user.id })),
      };
      switch (currentReportTypeId) {
        case 2:
          await this.getInvoiceFromApi(reportParams);
          break;
        case 3:
          await this.getCompensationFromApi(reportParams);
          break;
        case 5:
          await this.getIntermediateFromApi(reportParams);
          break;
        default:
          break;
      }
    },
  },
  watch: {
    selectedPeriod: function () {
      this.selectedBrands = this.company.data.companyBrands.filter(
        (x) => x.companyPeriodId == this.selectedPeriod.id
      );
    },
  },
  mounted() {
    this.selectedUsers = this.company.data.companyUsers.filter(
      (x) => x.user.roleId == 5
    );
    this.selectedPeriod =
      this.company.data.companyPeriods[
        this.company.data.companyPeriods.length - 1
      ];
    this.selectedBrands = this.company.data.companyBrands.filter(
      (x) => x.companyPeriodId == this.selectedPeriod.id
    );
  },
};
</script>
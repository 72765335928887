<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card
          tile
          outlined
          :color="company.data.companyColor.color1"
          class="mx-3"
        >
          <v-card-title class="py-2">
            <v-icon :color="company.data.companyColor.color3"
              >mdi-mortar-pestle-plus</v-icon
            >
            <span
              class="text-overline mx-2"
              :style="'color: ' + company.data.companyColor.color3"
              >Аптеки</span
            >
          </v-card-title>
          <v-card-subtitle class="py-2 mt-1 white">
            <v-row>
              <v-col cols="8">
                <v-text-field
                  outlined
                  dense
                  label="Поиск"
                  placeholder="Введите текст для поиска"
                  v-model="search"
                  hide-details
                  class="rounded-0"
                  :color="company.data.companyColor.color1"
                  prepend-icon="mdi-cloud-search-outline"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  :color="company.data.companyColor.color1"
                  v-model="selectedSearchColumn"
                  :items="searchColumns"
                  item-text="name"
                  item-value="id"
                  label="Столбец"
                  hide-details
                  return-object
                  prepend-icon="mdi-format-list-bulleted-type"
                  class="rounded-0"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider class="grey lighten-2" />
          <v-data-table
            v-if="company != null"
            :headers="headers"
            :items="list"
            class="elevation-0"
            item-key="id"
            fixed-header
            height="calc(100vh - 256px)"
            :footer-props="{
              'items-per-page-options': [15, -1],
              'items-per-page-text': 'Элементов на странице: ',
            }"
            :items-per-page="15"
          >
            <template v-slot:item.phone="{ item }">
              <code v-if="item.phone == null">н/д</code>
              <span else>{{ item.phone }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                fab
                text
                :color="company.data.companyColor.color1"
                @click="openUserBrandPlansDialog(item.user)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { dialogAction } from "../../enums/dialogAction";
export default {
  name: "Pharms",
  data: () => ({
    dialogAction: dialogAction,
    headers: [
      {
        text: "Наименование(Юр.лицо)",
        align: "center",
        value: "fullname",
      },
      {
        text: "Город",
        align: "center",
        value: "city.name",
      },
      {
        text: "Адрес",
        align: "center",
        value: "address",
      },
      {
        text: "Телефон",
        align: "center",
        value: "phone",
      },
      {
        text: "План",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
    search: null,
    selectedSearchColumn: null,
    searchColumns: null,
    list: [],
    tempList: [],
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
  },
  methods: {
    ...mapActions({
      commitCompanyUser: "companyUser/COMMIT_COMPANY_USER",
    }),
    ...mapMutations({
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
    }),
    openUserBrandPlansDialog(item) {
      this.commitCompanyUser(item);
      this.setDialogSettings({
        show: true,
        title: "Просмотр планов бренда пользователя",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 1200,
        actionId: this.dialogAction.PHARM_PLANS,
      });
    },
  },
  watch: {
    search: function () {
      if (this.search == null || this.search.length == 0) {
        this.list = this.tempList;
      } else {
        if (this.selectedSearchColumn != null) {
          let columnName = this.selectedSearchColumn.id.split(".");
          switch (columnName.length) {
            case 1:
              this.list = this.tempList.filter((x) =>
                x[columnName[0]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            case 2:
              this.list = this.tempList.filter((x) =>
                x[columnName[0]][columnName[1]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            case 3:
              this.list = this.tempList.filter((x) =>
                x[columnName[0]][columnName[1]][columnName[2]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            default:
              break;
          }
        }
      }
    },
  },
  async mounted() {
    this.searchColumns = this.headers
      .filter((x) => x.value != "actions")
      .map((x) => ({
        id: x.value,
        name: x.text,
      }));

    this.selectedSearchColumn = this.searchColumns[0];

    let pharmList = this.company.data.companyUsers.map(
      (x) => x.user.medicalRepresentativeUserPharms
    );

    let pharmListRes = pharmList[0];
    for (var j = 0; j < pharmList.length; j++) {
      pharmListRes = [...new Set([...pharmListRes, ...pharmList[j]])];
    }

    let uniquePharmList = [
      ...new Map(
        pharmListRes.map((item) => [item["userPharmId"], item])
      ).values(),
    ];

    let tempList = uniquePharmList
      .map((x) => x.userPharm)
      .filter((x) => x.pharmcenterUserId != null);

    this.list = tempList.sort(function (a, b) {
      if (a.user.isTest < b.user.isTest) {
        return 1;
      } else if (a.user.isTest > b.user.isTest) {
        return -1;
      } else {
        if (a.user.fullname > b.user.fullname) return 1;
        else if (a.user.fullname < b.user.fullname) return -1;
        else return 0;
      }
    });
    this.tempList = this.list;
  },
};
</script>
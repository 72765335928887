import report from '../api/index'

const General = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.General(query))
    })
}

const GeneralPharmIT = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.GeneralPharmIT(query))
    })
}

const Invoice = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.Invoice(query))
    })
}

const Compensation = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.Compensation(query))
    })
}

const Intermediate = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.Intermediate(query))
    })
}

const ProducerInvoice = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.ProducerInvoice(query))
    })
}

const ProducerCompensation = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.ProducerCompensation(query))
    })
}

const ProducerIntermadiate = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.ProducerIntermadiate(query))
    })
}

const PharmacyGeneral = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.PharmacyGeneral(query))
    })
}

const PharmacyInvoice = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.PharmacyInvoice(query))
    })
}

const PharmacyCompensation = (query) => {
    return new Promise((resolve) => {
        resolve(report.report.PharmacyCompensation(query))
    })
}

export default {
    namespaced: true,
    state: {
        report: [],
    },
    getters: {
        GET_REPORT(state) {
            return state.report;
        },
    },
    mutations: {
        SET_REPORT(state, payload) {
            state.report = payload;
        },
    },
    actions: {
        async GET_GENERAL_FROM_API({ commit }, payload) {
            await General(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_GENERAL_PHARMIT_FROM_API({ commit }, payload) {
            await GeneralPharmIT(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_INVOICE_FROM_API({ commit }, payload) {
            await Invoice(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_COMPENSATION_FROM_API({ commit }, payload) {
            await Compensation(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_INTERMEDIATE_FROM_API({ commit }, payload) {
            await Intermediate(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_PRODUCER_INVOICE_FROM_API({ commit }, payload) {
            await ProducerInvoice(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_PRODUCER_COMPENSATION_FROM_API({ commit }, payload) {
            await ProducerCompensation(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_PRODUCER_INTERMEDIATE_FROM_API({ commit }, payload) {
            await ProducerIntermadiate(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_PHARMACY_GENERAL_FROM_API({ commit }, payload) {
            await PharmacyGeneral(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_PHARMACY_INVOICE_FROM_API({ commit }, payload) {
            await PharmacyInvoice(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async GET_PHARMACY_COMPENSATION_FROM_API({ commit }, payload) {
            await PharmacyCompensation(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
    }
}
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Наименование периода"
          placeholder="Введите наименование периода"
          v-model="companyPeriod.name"
          hide-details
          class="rounded-0"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-menu
          ref="menuFrom"
          v-model="menuFrom"
          :close-on-content-click="false"
          :return-value.sync="companyPeriod.dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="companyPeriod.dateFrom"
              label="Дата начала"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              class="rounded-0"
              hide-details
              :color="company.data.companyColor.color1"
            ></v-text-field>
          </template>
          <v-date-picker v-model="companyPeriod.dateFrom" no-title scrollable :color="company.data.companyColor.color1">
            <v-spacer></v-spacer>
            <v-btn
              text
              :color="company.data.companyColor.color1"
              @click="$refs.menuFrom.save(companyPeriod.dateFrom)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          ref="menuTo"
          v-model="menuTo"
          :close-on-content-click="false"
          :return-value.sync="companyPeriod.dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="companyPeriod.dateTo"
              label="Дата начала"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              class="rounded-0"
              hide-details
              :color="company.data.companyColor.color1"
            ></v-text-field>
          </template>
          <v-date-picker v-model="companyPeriod.dateTo" no-title scrollable :color="company.data.companyColor.color1">
            <v-spacer></v-spacer>
            <v-btn
              text
              :color="company.data.companyColor.color1"
              @click="$refs.menuTo.save(companyPeriod.dateTo)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn outlined tile :color="company.data.companyColor.color1" @click="update()"
          >Редактировать</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "UpdatePeriod",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyPeriod: "companyPeriod/GET_COMPANY_PERIOD",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
      set: function (newVal) {
        this.setCompany(newVal);
      }
    },
    companyPeriod: {
      get: function () {
        return this.getCompanyPeriod;
      },
      set: function (newVal) {
        return this.setCompanyPeriod(newVal);
      },
    },
  },
  data: () => ({
    dialogAction: dialogAction,
    menuFrom: false,
    menuTo: false,
  }),
  methods: {
    ...mapActions({
      updateCompanyPeriodFromApi:
        "companyPeriod/UPDATE_COMPANY_PERIOD_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setCompanyPeriod: "companyPeriod/SET_COMPANY_PERIOD",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setCompany: "company/SET_COMPANY"
    }),
    async update() {
      if (
        this.companyPeriod.name != null &&
        this.companyPeriod.name.length > 3 &&
        this.companyPeriod.dateFrom != null &&
        this.companyPeriod.dateTo != null
      ) {
        await this.updateCompanyPeriodFromApi({
          id: this.companyPeriod.id,
          name: this.companyPeriod.name,
          dateFrom: this.companyPeriod.dateFrom,
          dateTo: this.companyPeriod.dateTo,
        });
        if (this.companyPeriod.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "Период успешно обновлен!",
          });
          this.setDialogSettings({
            show: false,
            title: "Модальное окно",
            color: this.company.data.companyColor.color1,
            persistent: true,
            maxWidth: 600,
            actionId: this.dialogAction.EMPTY,
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при обновлении периода!",
          });
        }
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Введите корректно данные!",
        });
      }
    },
  },
};
</script>
export default function(instance){
    return{
        GetPlanByCompanyPeriodId(payload){
            return instance.get('/Calculate/GetPlanByCompanyPeriodId?periodId=' + payload.periodId);
        },
        GetSkuPlanByBrandId(payload){
            return instance.get('/Calculate/GetSkuPlanByBrandId?periodId=' + payload.periodId + '&brandId=' + payload.brandId + '&companyId=' + payload.companyId);
        },
        GetPharmPlanByCompanyPeriodId(payload){
            return instance.get('/Calculate/GetPharmPlanByCompanyPeriodId?periodId=' + payload.periodId + '&userId=' + payload.userId);
        },
        GetPharmSkuPlanByBrandId(payload){
            return instance.get('/Calculate/GetPharmSkuPlanByBrandId?periodId=' + payload.periodId+ '&brandId=' + payload.brandId+ '&userId=' + payload.userId + '&companyId=' + payload.companyId);
        },
    }
}

export default function(instance){
    return{
        Commit(payload){
            return instance.put("/UserPharm/Commit", payload);
        },
        Update(payload){
            return instance.post("/UserPharm/Update", payload);
        },
    }
}

import calculate from '../api/index'

const GetPlanByCompanyPeriodId = (query) => {
    return new Promise((resolve) => {
        resolve(calculate.calculate.GetPlanByCompanyPeriodId(query))
    })
}

const GetSkuPlanByBrandId = (query) => {
    return new Promise((resolve) => {
        resolve(calculate.calculate.GetSkuPlanByBrandId(query))
    })
}

const GetPharmPlanByCompanyPeriodId = (query) => {
    return new Promise((resolve) => {
        resolve(calculate.calculate.GetPharmPlanByCompanyPeriodId(query))
    })
}

const GetPharmSkuPlanByBrandId = (query) => {
    return new Promise((resolve) => {
        resolve(calculate.calculate.GetPharmSkuPlanByBrandId(query))
    })
}


export default {
    namespaced: true,
    state: {
        plans: [],
        skuPlans: []
    },
    getters: {
        GET_PLANS(state) {
            return state.plans;
        },
        GET_SKU_PLANS(state) {
            return state.skuPlans;
        },
    },
    mutations: {
        SET_PLANS(state, payload) {
            state.plans = payload;
        },
        SET_SKU_PLANS(state, payload) {
            state.skuPlans = payload;
        },
    },
    actions: {
        async GET_PLANS_FROM_API({ commit }, payload) {
            await GetPlanByCompanyPeriodId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_PLANS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PLANS', { succeeded: false });
                })
        },
        async GET_SKU_PLANS_FROM_API({ commit }, payload) {
            await GetSkuPlanByBrandId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_SKU_PLANS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_SKU_PLANS', { succeeded: false });
                })
        },
        async GET_PHARM_PLANS_FROM_API({ commit }, payload) {
            await GetPharmPlanByCompanyPeriodId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_PLANS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PLANS', { succeeded: false });
                })
        },
        async GET_PHARM_SKU_PLANS_FROM_API({ commit }, payload) {
            await GetPharmSkuPlanByBrandId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_SKU_PLANS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_SKU_PLANS', { succeeded: false });
                })
        },
    }
}

<style>
#container {
    height: 100%;
    width: 100%;
}

.text-gradient {
    background: -webkit-linear-gradient(45deg, #e3d8c2, #d0b4df);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.text-first {
    font-family: "Russo One", sans-serif;
    position: absolute;
    font-size: 3vw;
    top: 40%;
    left: 5%;
    width: 40%;
}

.text-second {
    font-family: "Russo One", sans-serif;
    position: absolute;
    font-size: 3vw;
    top: 40%;
    right: 5%;
    text-align: end;
}

.text-second span {
    font-size: 4vw;
}
</style>

<template>
    <div id="container">
        <div id="fst-text" class="text-first text-gradient"></div>
        <div id="scn-text" class="text-second text-gradient" style="opacity: 0">
            План выполнен
            <br />
            <span>на {{ percent }}%</span>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function fade(element) {
    var op = 1;

    var timer = setInterval(function () {
        if (op <= 0.1) {
            clearInterval(timer);
            element.style.display = 'none';
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, 50);
}

function unfade(element) {
    var op = 0.1;
    element.style.opacity = 0;
    element.style.display = 'block';

    var timer = setInterval(function () {
        if (op >= 1) clearInterval(timer);

        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += op * 0.1;
    }, 20);
}

const degsToRads = deg => (deg * Math.PI) / 180.0;

export default {
    name: 'RocketVisual',
    props: {
        brandName: {
            type: String,
            required: true,
            default: "PR Brand"
        },
        brandId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    data() {
        return {
            percent: 0,
            scene: null,
            camera: null,
            renderer: null,
            container: null,
            launchButton: null,
            visibButtons: [],
            controls: null,
            mixer: [],
            clock: null,
            flag: null,
            rocket: null,
            rocketParts: [],
            tanktower: null,
            tower: null,
            launchpad: null,
            light: null,
            ambient: null,
            stars: [],
            rocketSmoke: [],
            launchSmoke: [],
            poofSmoke: [],
            smokeLenght: 20,
            textMesh: null,
            textTypingDelay: null,
            isRocketLaunched: false,
            truck: null
        }
    },
    watch: {
        brandName: function () {
            clearTimeout(this.textTypingDelay);
            var compenent = this;
            this.textTypingDelay = setTimeout(function () {
                compenent.appendBrandText();
            }, 1000);
        }
    },
    methods: {
        async init() {
            this.container = document.getElementById('container');
            this.launchButton = document.getElementById('btn_launch');

            this.visibButtons["btn_porthole"] = document.getElementById('btn_porthole');
            this.visibButtons["btn_fairing"] = document.getElementById('btn_fairing');
            this.visibButtons["btn_engine"] = document.getElementById('btn_engine');
            this.visibButtons["btn_wings"] = document.getElementById('btn_wings');
            this.visibButtons["btn_body"] = document.getElementById('btn_body');

            this.scene = new THREE.Scene();
            this.scene.background = new THREE.Color("#fff0b8");

            let persp = this.container.clientWidth / this.container.clientHeight;

            // PerspectiveCamera
            this.camera = new THREE.PerspectiveCamera(25, persp, 1, 200);
            this.camera.position.set(25, 20, 25);

            // WebGLRenderer
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
            this.renderer.shadowMap.enabled = true;

            this.container.appendChild(this.renderer.domElement);

            this.clock = new THREE.Clock();
            this.ambient = new THREE.AmbientLight('white', 0.5);

            this.scene.add(this.ambient);

            this.scene.fog = new THREE.Fog("#fff0b8", 70, 75);

            // Helpers
            //this.appendOrbitControls();
            //this.appendGridHelper();

            // Objects
            await this.appendBrandText();
            await this.appendLaunchPad();
            await this.appendTankTower();
            await this.appendRocket();
            await this.appendTruck();
            await this.appendTower();
            await this.appendFlag();
            await this.appendGrass();
            this.appendGround();
            this.appendLight();

            // Effects
            //await this.appendLaunchSmoke();
            //await this.appendRocketSmoke();
            //await this.appendPoofSmoke();

            //this.animateFinishText();

            // Events
            window.addEventListener('resize', this.onWindowResize);

            var component = this;

            this.launchButton
                .addEventListener('mouseup', function () {
                    if (!component.isRocketLaunched) {
                        component.launchRocket();
                        localStorage.setItem('rocketProgress_' + component.brandId, 0);
                        component.isRocketLaunched = true;
                    }
                });

            this.visibButtons["btn_porthole"]
                .addEventListener('mouseup', function () {
                    component.buildAnimatePorthole()
                    localStorage.setItem('rocketProgress_'  + component.brandId, 2)
                });

            this.visibButtons["btn_fairing"]
                .addEventListener('mouseup', function () {
                    component.buildAnimateFairing()
                    localStorage.setItem('rocketProgress_'  + component.brandId, 4)
                });

            this.visibButtons["btn_engine"]
                .addEventListener('mouseup', function () {
                    component.buildAnimateEngine()
                    localStorage.setItem('rocketProgress_'  + component.brandId, 5)
                });

            this.visibButtons["btn_wings"]
                .addEventListener('mouseup', function () {
                    component.buildAnimateWings()
                    localStorage.setItem('rocketProgress_'  + component.brandId, 3)
                });

            this.visibButtons["btn_body"]
                .addEventListener('mouseup', function () {
                    component.buildAnimateBody()
                    localStorage.setItem('rocketProgress_'  + component.brandId, 1)
                });

            this.initRocketParts();
        },
        initRocketParts() {
            var progress = localStorage.getItem('rocketProgress_'  + this.brandId);
            progress = parseInt(progress, 10);
            if (progress && (progress > 0 && progress < 6)) {
                var visibFunc = [
                    () => {
                        this.rocketParts["body"].visible = true;
                    },
                    () => {
                        this.rocketParts["porthole"].visible = true;
                    },
                    () => {
                        this.rocketParts["wing_1"].visible = true;
                        this.rocketParts["wing_2"].visible = true;
                        this.rocketParts["wing_3"].visible = true;
                        this.rocketParts["wing_4"].visible = true;
                    },
                    () => {
                        this.rocketParts["fairing"].visible = true;
                    },
                    () => {
                        this.rocketParts["engine"].visible = true;
                    },
                ]

                for (let index = 1; index <= progress; index++) {
                    visibFunc[index - 1]();
                }
            }
        },
        animateFinishText() {
            var fstText = document.getElementById('fst-text');
            var scnText = document.getElementById('scn-text');
            var message = 'Вы успешно запустили ракету в космос!';

            var letters = Array.from(message);

            fstText.innerHTML = null;

            for (let index = 0; index < letters.length; index++) {

                setTimeout(function () {
                    fstText.innerHTML += letters[index]
                }, index * 50)
            }

            setTimeout(function () {
                unfade(scnText);
            }, 1000)

            var component = this;

            setTimeout(function () {
                for (let index = 0; index <= 100; index++) {
                    setTimeout(function () {
                        component.percent = index;
                    }, index * 40)
                }
            }, 2000)
        },
        appendOrbitControls() {
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.listenToKeyEvents(window);

            this.controls.enableDamping = true;
            this.controls.dampingFactor = 0.1;

            this.controls.screenSpacePanning = false;

            this.controls.minDistance = 1;
            this.controls.maxDistance = 200;

            this.controls.maxPolarAngle = Math.PI / 2;
        },
        async appendGround() {
            let geometry = new THREE.PlaneGeometry(500, 100);
            let material = new THREE.MeshStandardMaterial({ color: "#c4af74" });

            let mesh = new THREE.Mesh(geometry, material);
            mesh.rotation.x = (Math.PI / 2) * 3
            mesh.rotation.z = 0.785398
            mesh.receiveShadow = true;

            this.scene.add(mesh);
        },
        async appendGrass() {

            var loader = new THREE.TextureLoader();

            var textures = [];
            textures[0] = await loader.loadAsync('/static/textures/grass_1.png');
            textures[1] = await loader.loadAsync('/static/textures/grass_2.png');
            textures[2] = await loader.loadAsync('/static/textures/grass_4.png');

            var geo = new THREE.PlaneBufferGeometry(1, 1);

            for (let index = 0; index < 200; index++) {

                var posX = getRandomArbitrary(-40, 20);
                var posZ = getRandomArbitrary(-40, 20);

                if ((posX < -5 || posX > 5) || (posZ < -5 || posZ > 5)) {
                    var randTexture = Math.floor(Math.random() * 3);

                    var material = new THREE.MeshLambertMaterial({
                        transparent: true,
                        map: textures[randTexture]
                    });

                    let particle = new THREE.Mesh(geo, material);

                    particle.lookAt(this.camera.position);

                    particle.position.set(posX, 0.5, posZ);
                    this.scene.add(particle);
                }
            }
        },
        appendLight() {
            this.light = new THREE.DirectionalLight(0xffffff, 1);

            this.light.position.set(10, 10, 4);
            this.light.castShadow = true;
            this.light.shadow.mapSize.width = 2048;
            this.light.shadow.mapSize.height = 2048;
            this.light.shadow.radius = 2;

            this.light.shadow.camera.top = 50;
            this.light.shadow.camera.left = -50;
            this.light.shadow.camera.right = 50;
            this.light.shadow.camera.bottom = -50;

            this.scene.add(this.light);
        },
        appendGridHelper() {
            let gridHelper = new THREE.GridHelper(20, 20);
            this.scene.add(gridHelper);
        },
        async appendBrandText() {

            var fontLoader = new FontLoader();
            var fontPath = '/static/fonts/russo-one-regular.json';

            var font = await fontLoader.loadAsync(fontPath);

            var textSize = 1 / this.brandName.length * 5.6;
            textSize = textSize > 1 ? 1 : textSize;

            var textGeometry = new TextGeometry(this.brandName, {
                size: textSize,
                font: font,
                height: 0,
            });

            textGeometry.computeBoundingBox();
            textGeometry.center();

            var material = new THREE.MeshStandardMaterial({ color: "#9c2556" });

            if (this.textMesh !== null) {
                this.textMesh.geometry.dispose();
                this.textMesh.material.dispose();
                this.scene.remove(this.textMesh);
            }

            this.textMesh = new THREE.Mesh(textGeometry, material);

            this.textMesh.position.set(3.2, 0.4, 0);
            this.textMesh.rotation.x = degsToRads(-90);
            this.textMesh.rotation.y = degsToRads(21);
            this.textMesh.rotation.z = degsToRads(90);

            this.scene.add(this.textMesh);
        },
        async appendLaunchPad() {
            var manager = new THREE.LoadingManager();
            var gltfLoader = new FBXLoader(manager)
            var objectPath = '/static/models/launchpad.fbx';

            this.launchpad = await gltfLoader.loadAsync(objectPath);

            this.launchpad.scale.set(0.5, 0.5, 0.5);
            this.launchpad.position.set(0, 0, 0);

            this.launchpad.traverse(function (child) {

                if (child.isMesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            this.scene.add(this.launchpad)
        },
        async appendTankTower() {
            var manager = new THREE.LoadingManager();
            var gltfLoader = new FBXLoader(manager)
            var objectPath = '/static/models/tanktower.fbx';

            this.tanktower = await gltfLoader.loadAsync(objectPath);

            this.tanktower.scale.set(0.5, 0.5, 0.5);
            this.tanktower.position.set(-5, 0, 6);
            this.tanktower.rotation.set(0, -1.5, 0);

            this.tanktower.traverse(function (child) {

                if (child.isMesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            this.scene.add(this.tanktower)
        },
        async appendTower() {
            var manager = new THREE.LoadingManager();
            var gltfLoader = new FBXLoader(manager)
            var objectPath = '/static/models/tower.fbx';

            this.tower = await gltfLoader.loadAsync(objectPath);

            this.tower.scale.set(0.5, 0.5, 0.5);
            this.tower.position.set(-2, 0.5, 0);
            this.tower.rotation.set(0, degsToRads(0), 0);

            this.tower.traverse(function (child) {

                if (child.isMesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            this.scene.add(this.tower)
        },
        async appendFlag() {
            var manager = new THREE.LoadingManager();
            var gltfLoader = new GLTFLoader(manager)
            var objectPath = '/static/models/flag.gltf';

            this.flag = await gltfLoader.loadAsync(objectPath);

            this.flag.scene.scale.set(0.1, 0.1, 0.1);
            this.flag.scene.position.set(5, 0, -5);
            this.flag.scene.rotation.set(0, 1.2, 0);

            this.flag.scene.traverse(function (child) {

                if (child.isMesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            this.scene.add(this.flag.scene)

            this.mixer[5] = new THREE.AnimationMixer(this.flag.scene);

            var flagAnimation = this.flag.animations[0];
            var flagAction = this.mixer[5].clipAction(flagAnimation);
            flagAction.setLoop(THREE.LoopRepeat);
            flagAction.play();
        },
        async appendTruck() {
            var manager = new THREE.LoadingManager();
            var gltfLoader = new FBXLoader(manager)
            var objectPath = '/static/models/truck.fbx';

            this.truck = await gltfLoader.loadAsync(objectPath);

            this.truck.scale.set(0.1, 0.1, 0.1);
            this.truck.position.set(-12, 0, -12);
            this.truck.rotation.set(0, degsToRads(60), 0);

            this.truck.traverse(function (child) {

                if (child.isMesh) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            this.scene.add(this.truck)

            this.mixer[20] = new THREE.AnimationMixer(this.truck);

            var anim = this.truck.animations[4];
            var action = this.mixer[20].clipAction(anim);
            action.setLoop(THREE.LoopRepeat);
            action.play();
        },
        async appendRocket() {

            var partNames = [
                "body",
                "engine",
                "fairing",
                "porthole",
                "wing_1",
                "wing_2",
                "wing_3",
                "wing_4",
            ]

            this.rocket = new THREE.Group();

            for (const key in partNames) {

                var partname = partNames[key];

                var manager = new THREE.LoadingManager();
                var fbxLoader = new FBXLoader(manager)
                var objectPath = `/static/models/rocket_${partname}.fbx`;

                var part = await fbxLoader.loadAsync(objectPath);

                part.partname = partname;
                part.scale.set(0.5, 0.5, 0.5);
                part.rotation.y = degsToRads(90);

                part.traverse(function (child) {

                    if (child.isMesh) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                });

                part.visible = false;
                this.rocketParts[partname] = part;
                this.rocket.add(part);
            }

            this.rocket.position.y = 0.7;
            this.scene.add(this.rocket);
        },
        buildAnimateBody() {

            if (this.rocketParts["body"].visible) return;

            var btn = document.getElementById("btn_body").parentElement;
            btn.classList.add("mounted");

            var obj = this.rocketParts["body"];
            obj.visible = true;
            obj.position.y = 50;

            var keyFrames = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.2],
                [
                    0, 50, 0,
                    0, 1, 0,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            var clip = new THREE.AnimationClip('action', 2, [keyFrames]);

            this.mixer[6] = new THREE.AnimationMixer(obj);

            var cameraAction = this.mixer[6].clipAction(clip);
            cameraAction.clampWhenFinished = true;
            cameraAction.setLoop(THREE.LoopOnce);
            cameraAction.play();
        },
        buildAnimatePorthole() {

            if (!this.rocketParts["body"].visible ||
                this.rocketParts["porthole"].visible) return;

            var btn = document.getElementById("btn_porthole").parentElement;
            btn.classList.add("mounted");

            var obj = this.rocketParts["porthole"];
            obj.visible = true;
            obj.position.x = 50;

            var keyFrames = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.2],
                [
                    50, 0, 0,
                    1, 0, 0,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            var clip = new THREE.AnimationClip('action', 2, [keyFrames]);

            this.mixer[7] = new THREE.AnimationMixer(obj);

            var cameraAction = this.mixer[7].clipAction(clip);
            cameraAction.clampWhenFinished = true;
            cameraAction.setLoop(THREE.LoopOnce);
            cameraAction.play();
        },
        buildAnimateWings() {

            if (!this.rocketParts["porthole"].visible ||
                this.rocketParts["wing_1"].visible) return;

            var btn = document.getElementById("btn_wings").parentElement;
            btn.classList.add("mounted");

            for (let index = 1; index <= 4; index++) {

                this.rocketParts["wing_" + index].visible = true;
                this.rocketParts["wing_" + index].position.y = -50;
            }

            var keyFrames = [];
            var clips = [];

            keyFrames[1] = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.2],
                [
                    0, 0, -50,
                    0, 0, -1,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            keyFrames[2] = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.2],
                [
                    -50, 0, 0,
                    -1, 0, 0,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            keyFrames[3] = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.2],
                [
                    0, 0, 50,
                    0, 0, 1,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            keyFrames[4] = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.2],
                [
                    50, 0, 0,
                    1, 0, 0,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            for (let index = 1; index <= 4; index++) {

                var wing = this.rocketParts["wing_" + index];
                var kf = keyFrames[index];

                clips[index] = new THREE.AnimationClip('action', 2, [kf]);
                this.mixer[index + 10] = new THREE.AnimationMixer(wing);

                var cameraAction = this.mixer[index + 10].clipAction(clips[index]);
                cameraAction.clampWhenFinished = true;
                cameraAction.setLoop(THREE.LoopOnce);
                cameraAction.play();
            }
        },
        buildAnimateFairing() {

            if (!this.rocketParts["wing_1"].visible ||
                this.rocketParts["fairing"].visible) return;

            var btn = document.getElementById("btn_fairing").parentElement;
            btn.classList.add("mounted");

            var obj = this.rocketParts["fairing"];
            obj.visible = true;
            obj.position.y = 50;

            var keyFrames = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.2],
                [
                    0, 50, 0,
                    0, 1, 0,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            var clip = new THREE.AnimationClip('action', 2, [keyFrames]);

            this.mixer[9] = new THREE.AnimationMixer(obj);

            var cameraAction = this.mixer[9].clipAction(clip);
            cameraAction.clampWhenFinished = true;
            cameraAction.setLoop(THREE.LoopOnce);
            cameraAction.play();
        },
        buildAnimateEngine() {

            if (!this.rocketParts["fairing"].visible ||
                this.rocketParts["engine"].visible) return;

            var btn = document.getElementById("btn_engine").parentElement;
            btn.classList.add("mounted");

            var obj = this.rocketParts["engine"];
            obj.visible = true;

            obj.position.x = 50;

            var posKF = new THREE.VectorKeyframeTrack(
                '.position', [0, 1, 1.1, 2],
                [
                    0, -50, 0,
                    0, -1, 0,
                    0, -1, 0,
                    0, 0, 0
                ],
                THREE.InterpolateSmooth
            );

            var clip = new THREE.AnimationClip('action', 5, [posKF]);

            this.mixer[10] = new THREE.AnimationMixer(obj);

            var cameraAction = this.mixer[10].clipAction(clip);
            cameraAction.clampWhenFinished = true;
            cameraAction.setLoop(THREE.LoopOnce);
            cameraAction.play();

            fade(document.getElementById('plan_progress'))

            setTimeout(function () {
                unfade(document.getElementById('btn_launch'))
            }, 2000)
        },
        async launchRocket() {

            var hasInvisible = false;

            for (var key in this.rocketParts) {
                if (!this.rocketParts[key].visible) {
                    hasInvisible = true;
                    break;
                }
            }

            if (hasInvisible || this.rocket.isLaunched) return;

            this.rocket.isLaunched = true;
            localStorage.setItem('rocketProgress_'  + this.brandId, 0)

            fade(document.getElementById('btn_launch'));
            fade(document.getElementById('progress'));

            setTimeout(this.appendStars, 8200);
            setTimeout(this.appendRocketSmoke, 100);
            setTimeout(this.appendLaunchSmoke, 200);

            setTimeout(this.animateFinishText, 9000)

            setTimeout(function () {
                unfade(document.getElementById('btn_back'))
            }, 15000)

            // Scene animations

            var rocketPosKF = new THREE.VectorKeyframeTrack(
                '.position', [0, 3, 6],
                [
                    0, 0.7, 0,
                    0, 5.7, 0,
                    0, 30, 0,
                ],
                THREE.InterpolateSmooth
            );

            var rocketRotKF = new THREE.VectorKeyframeTrack(
                '.rotation', [0, 6],
                [
                    0, 0, 0,
                    0, 5, 0,
                ],
                THREE.InterpolateSmooth
            );

            var cameraPosKF = new THREE.VectorKeyframeTrack(
                '.position', [0, 6, 8, 8.5, 9],
                [
                    25, 20, 25,
                    25, 30, 25,
                    30, 30, 30,
                    30, 30, 30,
                    45, 30, 45,
                ],
                THREE.InterpolateSmooth
            );

            var backgrColKF = new THREE.VectorKeyframeTrack(
                '.background', [8.5, 9],
                [
                    1, 0.94, 0.72,
                    0.13, 0.10, 0.14,
                ],
                THREE.InterpolateSmooth
            );

            var sceneFogKF = new THREE.VectorKeyframeTrack(
                '.far', [7, 8],
                [
                    75,
                    200
                ],
                THREE.InterpolateSmooth
            );

            var dlightColKF = new THREE.VectorKeyframeTrack(
                '.color', [8.5, 9],
                [
                    1, 1, 1,
                    0.94, 0.81, 1,
                ],
                THREE.InterpolateSmooth
            );

            var rocketClip = new THREE.AnimationClip('action', 15, [rocketPosKF, rocketRotKF]);
            var cameraClip = new THREE.AnimationClip('action', 15, [cameraPosKF]);
            var backgrClip = new THREE.AnimationClip('action', 15, [backgrColKF]);
            var dlightClip = new THREE.AnimationClip('action', 15, [dlightColKF]);
            var scnFogClip = new THREE.AnimationClip('action', 15, [sceneFogKF]);

            this.mixer[0] = new THREE.AnimationMixer(this.rocket);
            this.mixer[1] = new THREE.AnimationMixer(this.camera);
            this.mixer[2] = new THREE.AnimationMixer(this.scene);
            this.mixer[3] = new THREE.AnimationMixer(this.ambient);
            this.mixer[4] = new THREE.AnimationMixer(this.scene.fog);

            var rocketAction = this.mixer[0].clipAction(rocketClip);
            rocketAction.clampWhenFinished = true;
            rocketAction.setLoop(THREE.LoopOnce);
            rocketAction.play();

            var cameraAction = this.mixer[1].clipAction(cameraClip);
            cameraAction.clampWhenFinished = true;
            cameraAction.setLoop(THREE.LoopOnce);
            cameraAction.play();

            var backgrAction = this.mixer[2].clipAction(backgrClip);
            backgrAction.clampWhenFinished = true;
            backgrAction.setLoop(THREE.LoopOnce);
            backgrAction.play();

            var dlightAction = this.mixer[3].clipAction(dlightClip);
            dlightAction.clampWhenFinished = true;
            dlightAction.setLoop(THREE.LoopOnce);
            dlightAction.play();

            dlightAction = this.mixer[4].clipAction(scnFogClip);
            dlightAction.clampWhenFinished = true;
            dlightAction.setLoop(THREE.LoopOnce);
            dlightAction.play();
        },
        async appendLaunchSmoke() {

            var loader = new THREE.TextureLoader();
            loader.crossOrigin = '';

            var texture = await loader.loadAsync('/static/textures/smoke.png');

            var smokeGeo = new THREE.PlaneBufferGeometry(2, 2);

            for (let index = 0; index < 20; index++) {

                var material = new THREE.MeshLambertMaterial({
                    transparent: true,
                    map: texture
                });

                let particle = new THREE.Mesh(smokeGeo, material);

                particle.outAnimPause = true;

                setTimeout(function () {
                    particle.outAnimPause = false;
                }, 60 * index);

                particle.rotationOffset = Math.random() * 360;
                particle.material.depthWrite = false;
                particle.offsetDistance = 0;

                this.scene.add(particle);
                this.launchSmoke.push(particle);
            }
        },
        animateLaunchSmoke(delta) {
            if (this.launchSmoke.length < 1) return;
            var distanceFar = 25 - this.rocket.position.y * 3;

            for (var i = 0; i < this.launchSmoke.length; i++) {

                var particle = this.launchSmoke[i];

                particle.lookAt(this.camera.position)
                particle.rotation.z -= particle.rotationOffset;

                var distanceSpeed = getRandomArbitrary(40, 60);
                particle.scale.x = (particle.offsetDistance + 3) * 0.5;
                particle.scale.y = (particle.offsetDistance + 3) * 0.5;
                particle.scale.z = (particle.offsetDistance + 3) * 0.5;

                particle.position.x = 0;
                particle.position.y = 0;
                particle.position.z = -4 - particle.offsetDistance;

                if (!particle.outAnimPause) {
                    particle.offsetDistance += distanceSpeed * delta;
                }

                particle.material.color.setRGB(1, 1, 1);
                particle.material.opacity = 1 - particle.offsetDistance / distanceFar;

                if (particle.offsetDistance < 5) {
                    var coff = this.rocket.position.y * 0.5;
                    particle.material.color.setRGB(1 + coff, 0.6 + coff, 0.2 + coff);
                }

                if (particle.offsetDistance > distanceFar) {
                    particle.offsetDistance = 0
                }

            }
        },
        async appendRocketSmoke() {

            var loader = new THREE.TextureLoader();
            loader.crossOrigin = '';

            var texture = await loader.loadAsync('/static/textures/smoke.png');

            var smokeGeo = new THREE.PlaneBufferGeometry(2, 2);

            for (let index = 0; index < 20; index++) {

                var smokeMaterial = new THREE.MeshLambertMaterial({
                    map: texture, transparent: true,
                });

                let particle = new THREE.Mesh(smokeGeo, smokeMaterial);

                particle.outAnimPause = true;

                setTimeout(function () {
                    particle.outAnimPause = false;
                }, 60 * index);

                particle.castShadow = true;
                particle.rotationOffset = Math.random() * 360;
                particle.material.depthWrite = false;
                particle.offsetDistance = 0;

                this.scene.add(particle);
                this.rocketSmoke.push(particle);
            }
        },
        animateRocketSmoke(delta) {
            if (this.rocketSmoke.length < 1) return;

            var distanceFar = 20;

            for (var i = 0; i < this.rocketSmoke.length; i++) {

                var particle = this.rocketSmoke[i];

                particle.lookAt(this.camera.position)
                particle.rotation.z -= particle.rotationOffset;

                var distanceSpeed = getRandomArbitrary(40, 60);
                particle.scale.x = (particle.offsetDistance + 1) * 0.5;
                particle.scale.y = (particle.offsetDistance + 1) * 0.5;
                particle.scale.z = (particle.offsetDistance + 1) * 0.5;

                particle.position.x = this.rocket.position.x + distanceSpeed * 0.001;
                particle.position.y = this.rocket.position.y - particle.offsetDistance + 1;
                particle.position.z = this.rocket.position.z;

                if (!particle.outAnimPause) {
                    particle.offsetDistance += distanceSpeed * delta;
                }

                particle.material.color.setRGB(1, 1, 1);
                particle.material.opacity = 1 - particle.offsetDistance / distanceFar;

                if (particle.offsetDistance < 5) {
                    var coff = particle.offsetDistance / 5;
                    particle.material.color.setRGB(1 + coff, 0.6 + coff, 0.2 + coff);
                }

                if (particle.offsetDistance > distanceFar) {
                    particle.offsetDistance = 0
                }
            }
        },
        appendStars() {
            for (var z = 0; z < 80; z++) {

                var geometry = new THREE.SphereGeometry(0.5, 0.5, 0.5)
                var material = new THREE.MeshBasicMaterial({ color: 0xffffff });
                var star = new THREE.Mesh(geometry, material)

                star.position.x = getRandomArbitrary(-25, 25);
                star.position.y = getRandomArbitrary(-25, 25);
                star.position.z = getRandomArbitrary(-25, 25);
                star.speed = getRandomArbitrary(30, 60)

                star.scale.x = star.scale.z = 0.05;

                this.scene.add(star);

                this.stars.push(star);
            }
        },
        animateStars(delta) {

            if (this.stars.length < 1) return;

            for (var i = 0; i < this.stars.length; i++) {

                var star = this.stars[i];

                star.position.y -= star.speed * delta;

                if (star.position.y < 20) star.position.y += 40;
            }
        },
        onWindowResize() {

            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(window.innerWidth, window.innerHeight);
        },
        animate() {
            requestAnimationFrame(this.animate);
            this.renderer.render(this.scene, this.camera);

            if (this.controls) {
                this.controls.update();
            } else {
                if (this.rocket && this.camera) {
                    var x = this.rocket.position.x
                    var y = this.rocket.position.y
                    var z = this.rocket.position.z

                    var targetPos = new THREE.Vector3(x, y + 4, z)

                    this.camera.lookAt(targetPos)
                }
            }

            const delta = this.clock.getDelta();

            for (const key in this.mixer) {
                this.mixer[key].update(delta);
            }

            this.animateRocketSmoke(delta);
            this.animateLaunchSmoke(delta);
            this.animateStars(delta);
        },
    },
    async mounted() {
        await this.init();
        this.animate();
    }
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="3" lg="3" xl="3">
        <v-card
          elevation="0"
          tile
          outlined
          :color="company.data.companyColor.color1"
          class="ml-3"
        >
          <v-subheader
            class="font-weight-bold"
            :style="'color: ' + company.data.companyColor.color3"
            >Города</v-subheader
          >
          <v-divider />
          <v-list dense height="calc(100vh - 196px)" class="overflow-y-auto">
            <v-list-item-group v-model="selectedCityId">
              <template v-for="city in rmCities">
                <v-list-item :key="'city_' + city.id" :value="city.id">
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">{{
                      city.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="'citydv_' + city.id" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" md="3" lg="3" xl="3">
        <v-card
          elevation="0"
          tile
          outlined
          :color="company.data.companyColor.color1"
        >
          <v-subheader
            class="font-weight-bold"
            :style="'color: ' + company.data.companyColor.color3"
            >Медицинские представители</v-subheader
          >
          <v-divider />
          <v-list dense height="calc(100vh - 196px)" class="overflow-y-auto">
            <v-list-item-group v-model="selectedMpId">
              <template
                v-for="mp in company.data.companyUsers.filter(
                  (x) =>
                    x.user.roleId == 4 &&
                    (selectedCityId != null && selectedCityId != 0
                      ? x.user.userCities
                          .map((z) => z.cityId)
                          .includes(selectedCityId)
                      : true)
                )"
              >
                <v-list-item :key="'mp_' + mp.id" :value="mp.user.id">
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">{{
                      mp.user.fullname
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="'mpdv_' + mp.id" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" lg="6" xl="6">
        <v-card
          elevation="0"
          tile
          outlined
          :color="company.data.companyColor.color1"
          class="mr-3"
          :loading="pharmLoader"
        >
          <v-progress-linear
            v-show="pharmLoader"
            slot="progress"
            :color="company.data.companyColor.color2"
            indeterminate
          ></v-progress-linear>
          <v-subheader
            class="font-weight-bold"
            :style="'color: ' + company.data.companyColor.color3"
            >Аптеки</v-subheader
          >
          <v-divider />
          <v-card-subtitle class="py-2 mt-1 white">
            <v-row>
              <v-col cols="8">
                <v-text-field
                  outlined
                  dense
                  label="Поиск"
                  placeholder="Введите текст для поиска"
                  v-model="search"
                  hide-details
                  class="rounded-0"
                  :color="company.data.companyColor.color1"
                  prepend-icon="mdi-cloud-search-outline"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  :color="company.data.companyColor.color1"
                  v-model="selectedSearchColumn"
                  :items="searchColumns"
                  item-text="name"
                  item-value="id"
                  label="Столбец"
                  hide-details
                  return-object
                  prepend-icon="mdi-format-list-bulleted-type"
                  class="rounded-0"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider class="grey lighten-2" />
          <v-list
            v-if="list != null"
            dense
            height="calc(100vh - 256px)"
            class="overflow-y-auto"
          >
            <v-list-item-group v-model="selectedPharmId">
              <template v-for="pharm in list">
                <v-list-item
                  :key="'pharm_' + pharm.id"
                  :value="pharm.user.id"
                  three-line
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">{{
                      pharm.user.fullname
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">{{
                      pharm.fullname
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-wrap"
                      >Адрес: {{ pharm.address }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn
                      text
                      fab
                      small
                      class="my-auto"
                      :color="company.data.companyColor.color1"
                      @click="openPharmPlan(pharm.user)"
                      :disabled="pharm.pharmcenterUserId == null"
                    >
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider :key="'pharmdv_' + pharm.id" />
              </template>
            </v-list-item-group>
          </v-list>
          <div
            v-else
            style="height: calc(100vh - 256px)"
            class="text-center white"
          >
            <v-icon x-large color="red">mdi-cloud-alert</v-icon>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "Users",
  data: () => ({
    dialogAction: dialogAction,
    selectedMpId: null,
    selectedPharmId: null,
    selectedCityId: 0,
    rmCities: null,
    pharmLoader: false,
    search: null,
    searchColumns: [
      {
        id: "fullname",
        name: "Наименование",
      },
      {
        id: "address",
        name: "Адрес",
      },
    ],
    selectedSearchColumn: {
      id: "fullname",
      name: "Наименование",
    },
    list: [],
    tempList: [],
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
  },
  methods: {
    ...mapActions({
      commitCompanyUser: "companyUser/COMMIT_COMPANY_USER",
    }),
    ...mapMutations({
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
    }),
    openPharmPlan(item) {
      this.commitCompanyUser(item);
      this.setDialogSettings({
        show: true,
        title: "Планы пользователя",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 1200,
        actionId: this.dialogAction.PHARM_PLANS,
      });
    },
  },
  watch: {
    selectedMpId: function () {
      var rmMpList = this.company.data.companyUsers
        .filter((x) => x.userId == this.selectedMpId)
        .map((x) => x.user.medicalRepresentativeUserPharms);

      let rmMpListRes = rmMpList[0];
      for (var j = 0; j < rmMpList.length; j++) {
        rmMpListRes = [...new Set([...rmMpListRes, ...rmMpList[j]])];
      }

      let uniqueRmMpList = [
        ...new Map(
          rmMpListRes.map((item) => [item["userPharmId"], item])
        ).values(),
      ];
      let tempList = uniqueRmMpList
        .map((x) => x.userPharm)
        .filter((x) => x.pharmcenterUserId != null);

      this.list = tempList.sort(function (a, b) {
        if (a.user.isTest < b.user.isTest) {
          return 1;
        } else if (a.user.isTest > b.user.isTest) {
          return -1;
        } else {
          if (a.user.fullname > b.user.fullname) return 1;
          else if (a.user.fullname < b.user.fullname) return -1;
          else return 0;
        }
      });
      this.tempList = this.list;
    },
    selectedCityId: function () {
      var rmMpList = this.company.data.companyUsers;
      var rmMpListRes = rmMpList
        .filter((x) =>
          x.user.userCities.map((z) => z.cityId).includes(this.selectedCityId)
        )
        .map((x) => x.user.medicalRepresentativeUserPharms);
      let rmMpListRess = rmMpListRes[0];
      for (var k = 0; k < rmMpListRes.length; k++) {
        rmMpListRess = [...new Set([...rmMpListRess, ...rmMpListRes[k]])];
      }
      let uniqueRmMpList = [
        ...new Map(
          rmMpListRess.map((item) => [item["userPharmId"], item])
        ).values(),
      ];
      let tempList = uniqueRmMpList
        .map((x) => x.userPharm)
        .filter((x) => x.pharmcenterUserId != null);

      this.list = tempList.sort(function (a, b) {
        if (a.user.isTest < b.user.isTest) {
          return 1;
        } else if (a.user.isTest > b.user.isTest) {
          return -1;
        } else {
          if (a.user.fullname > b.user.fullname) return 1;
          else if (a.user.fullname < b.user.fullname) return -1;
          else return 0;
        }
      });
      this.tempList = this.list;
    },
    search: function () {
      if (this.search == null || this.search.length == 0) {
        this.list = this.tempList;
      } else {
        if (this.selectedSearchColumn != null) {
          let columnName = this.selectedSearchColumn.id.split(".");
          switch (columnName.length) {
            case 1:
              this.list = this.tempList.filter((x) =>
                x[columnName[0]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            case 2:
              this.list = this.tempList.filter((x) =>
                x[columnName[0]][columnName[1]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            case 3:
              this.list = this.tempList.filter((x) =>
                x[columnName[0]][columnName[1]][columnName[2]]
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
              );
              break;
            default:
              break;
          }
        }
      }
    },
  },
  async mounted() {
    let allMpCities = this.company.data.companyUsers
      .filter((x) => x.user.roleId == 4)
      .map((x) =>
        x.user.userCities.map((z) => ({ id: z.city.id, name: z.city.name }))
      );

    let res = allMpCities[0];
    for (var i = 0; i < allMpCities.length; i++) {
      res = [...new Set([...res, ...allMpCities[i]])];
    }

    let mpCities = Object.values(
      res.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );

    mpCities.unshift({
      id: 0,
      name: "Все",
    });

    let uniqueCities = [
      ...new Map(mpCities.map((item) => [item["id"], item])).values(),
    ];

    this.rmCities = uniqueCities;

    var rmMpList = this.company.data.companyUsers.map(
      (x) => x.user.medicalRepresentativeUserPharms
    );
    let rmMpListRes = rmMpList[0];
    for (var j = 0; j < rmMpList.length; j++) {
      rmMpListRes = [...new Set([...rmMpListRes, ...rmMpList[j]])];
    }
    let uniqueRmMpList = [
      ...new Map(
        rmMpListRes.map((item) => [item["userPharmId"], item])
      ).values(),
    ];
    let tempList = uniqueRmMpList
      .map((x) => x.userPharm)
      .filter((x) => x.pharmcenterUserId != null);

    this.list = tempList.sort(function (a, b) {
      if (a.user.isTest < b.user.isTest) {
        return 1;
      } else if (a.user.isTest > b.user.isTest) {
        return -1;
      } else {
        if (a.user.fullname > b.user.fullname) return 1;
        else if (a.user.fullname < b.user.fullname) return -1;
        else return 0;
      }
    });
    this.tempList = this.list;
  },
};
</script>
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Наименование плана"
          placeholder="Введите наименование плана"
          v-model="name"
          hide-details
          class="rounded-0"
          :color="company.data.companyColor.color1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          outlined
          dense
          :color="company.data.companyColor.color1"
          v-model="selectedPeriod"
          :items="company.data.companyPeriods"
          item-text="name"
          item-value="id"
          label="Выберите период"
          hide-details
          return-object
          class="rounded-0"
        >
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-icon :color="company.data.companyColor.color1"
                >mdi-calendar-clock</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle
                >{{ new Date(data.item.dateFrom).toLocaleDateString() }} -
                {{
                  new Date(data.item.dateTo).toLocaleDateString()
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table class="elevation-3 rounded-0">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="'hdr_' + index"
                  class="text-center"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in plans" :key="index">
                <td>{{ item.companyBrandName }}</td>
                <td>
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    v-model="item.quantity"
                    hide-details
                    class="rounded-0"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    v-model="item.amount"
                    hide-details
                    class="rounded-0"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    v-model="item.discount"
                    hide-details
                    class="rounded-0"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          outlined
          tile
          :color="company.data.companyColor.color1"
          @click="add()"
          >Добавить</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "AddPeriod",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyPlan: "companyPlan/GET_COMPANY_PLAN",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    companyPlan: {
      get: function () {
        return this.getCompanyPlan;
      },
      set: function (newVal) {
        return this.setCompanyPlan(newVal);
      },
    },
  },
  data: () => ({
    selectedPeriod: null,
    dialogAction: dialogAction,
    name: null,
    headers: [
      { text: "Бренд" },
      { text: "Количество" },
      { text: "Сумма" },
      { text: "Скидка" },
    ],
    plans: null,
  }),
  methods: {
    ...mapActions({
      addCompanyPlanFromApi: "companyPlan/ADD_COMPANY_PLAN_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setCompanyPlan: "companyPlan/SET_COMPANY_PLAN",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
    }),
    async add() {
      if (this.name != null && this.name.length > 3) {
        await this.addCompanyPlanFromApi({
          companyId: this.company.data.id,
          name: this.name,
          list: this.plans
            .filter((x) => x.quantity != 0 && x.amount != 0 && x.discount != 0)
            .map((x) => ({
              quantity: x.quantity,
              discount: x.discount,
              amount: x.amount,
              companyBrandId: x.companyBrandId,
            })),
        });
        if (this.companyPlan.succeeded) {
          //update company object in vuex store
          this.company.data.companyPlans.push(this.companyPlan.data);
          //

          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: "План успешно добавлен!",
          });

          this.setDialogSettings({
            show: false,
            title: "Модальное окно",
            color: this.company.data.companyColor.color1,
            persistent: true,
            maxWidth: 600,
            actionId: this.dialogAction.EMPTY,
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при добавлении плана!",
          });
        }
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Введите корректно данные!",
        });
      }
    },
    getPlans() {
      this.plans = this.company.data.companyBrands.filter(x => x.companyPeriodId == this.selectedPeriod.id).map((x) => ({
        companyBrandId: x.id,
        companyBrandName: x.brand.name,
        quantity: 0,
        amount: 0,
        discount: 0,
      }));
    },
  },
  watch: {
    selectedPeriod: function () {
      this.getPlans();
    },
  },
};
</script>
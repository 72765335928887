<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selectedSkus"
          :headers="headers"
          :items="selectedBrand.brandSkus"
          class="elevation-3 mx-3 rounded-0"
          disable-pagination
          hide-default-footer
          fixed-header
          height="60vh"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "BrandSkus",
  data: () => ({
    selectedSkus: [],
    headers: [
      { text: "Id", align: "center", value: "sku.id" },
      { text: "Наименование", align: "center", value: "sku.name" },
    ],
  }),
  computed: {
    ...mapGetters({
      getSelectedBrand: "brand/GET_SELECTED_BRAND",
    }),
    selectedBrand: {
      get: function () {
        return this.getSelectedBrand;
      },
    },
  },
};
</script>
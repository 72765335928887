import pharmacy from '../api/index'

const GetAll = () => {
    return new Promise((resolve) => {
        resolve(pharmacy.pharmacy.GetAll())
    })
}

const GetUsa = () => {
    return new Promise((resolve) => {
        resolve(pharmacy.pharmacy.GetUsa())
    })
}

export default {
    namespaced: true,
    state: {
        pharmacies: [],
    },
    getters: {
        GET_PHARMACIES(state) {
            return state.pharmacies;
        },
    },
    mutations: {
        SET_PHARMACIES(state, payload) {
            state.pharmacies = payload;
        },
    },
    actions: {
        async GET_PHARMACIES_FROM_API({ commit }) {
            await GetAll().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_PHARMACIES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PHARMACIES', { succeeded: false });
                })
        },
        async GET_USA_PHARMACIES_FROM_API({ commit }) {
            await GetUsa().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_PHARMACIES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PHARMACIES', { succeeded: false });
                })
        },
    }
}
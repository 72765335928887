<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mx-3" tile :color="company.data.companyColor.color1">
          <v-card-title class="py-2">
            <v-icon :color="company.data.companyColor.color3">mdi-calendar-clock</v-icon>
            <span class="text-overline mx-2" :style="'color: ' + company.data.companyColor.color3">Периоды</span>
            <v-spacer />
            <v-btn-toggle class="mx-3" tile>
              <v-btn small :color="company.data.companyColor.color2" outlined tile @click="add()">Добавить</v-btn>
              <v-btn small color="red" outlined tile @click="confirmRemove()">Удалить</v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-divider />
          <v-row>
            <v-col cols="12">
              <v-data-table :checkbox-color="company.data.companyColor.color2" v-model="selectedPeriods"
                :headers="headers" :items="company.data.companyPeriods" class="elevation-0" :single-select="false"
                item-key="id" show-select disable-pagination hide-default-footer fixed-header
                height="calc(100vh - 196px)">
                <template v-slot:item.actions="{ item }">
                  <v-btn x-small fab text :color="company.data.companyColor.color2" @click="update(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.dateFrom="{ item }">
                  {{ new Date(item.dateFrom).toLocaleDateString() }}
                </template>
                <template v-slot:item.dateTo="{ item }">
                  {{ new Date(item.dateTo).toLocaleDateString() }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "Periods",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getDeleteStatus: "companyPeriod/GET_DELETE_STATUS",
      getConfirmSettings: "common/GET_CONFIRM_SETTINGS",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    deleteStatus: {
      get: function () {
        return this.getDeleteStatus;
      },
    },
    confirmSettigs: {
      get: function () {
        return this.getConfirmSettings;
      }
    }
  },
  data: () => ({
    selectedPeriods: [],
    dialogAction: dialogAction,
    headers: [
      { text: "ID", align: "center", value: "id" },
      { text: "Наименование", align: "center", value: "name" },
      { text: "Дата начала", align: "center", value: "dateFrom" },
      { text: "Дата окончания", align: "center", value: "dateTo" },
      { text: "Действия", align: "center", value: "actions", sortable: false },
    ],
  }),
  methods: {
    ...mapActions({
      commitCompanyPeriod: "companyPeriod/COMMIT_COMPANY_PERIOD",
      deleteRange: "companyPeriod/DELETE_RANGE_COMPANY_PERIOD_FROM_API",
    }),
    ...mapMutations({
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setSnackBar: "common/SET_SNACKBAR",
      setConfirmSettings: "common/SET_CONFIRM_SETTINGS",
    }),
    add() {
      this.setDialogSettings({
        show: true,
        title: "Добавление периода",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 600,
        actionId: this.dialogAction.ADD_PERIOD,
      });
    },
    update(item) {
      this.commitCompanyPeriod(item);
      this.setDialogSettings({
        show: true,
        title: "Редактирование периода",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 600,
        actionId: this.dialogAction.UPDATE_PERIOD,
      });
    },
    confirmRemove() {
      if (this.selectedPeriods.length > 0) {
        this.setConfirmSettings({
          title: 'Вы уверены, что хотите удалить выбранные периоды?',
        });
        this.setDialogSettings({
          show: true,
          title: "Подтвердите действие",
          color: this.company.data.companyColor.color1,
          persistent: true,
          maxWidth: 400,
          actionId: this.dialogAction.CONFIRM,
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Выберите планы для удаления!",
        });
      }
    },
    async remove() {
      this.setConfirmSettings({
        isConfirmed: false,
      });
      await this.deleteRange({
        data: { list: this.selectedPeriods.map((x) => ({ id: x.id })) },
      });
      if (this.deleteStatus.data) {
        this.company.data.companyPeriods =
          this.company.data.companyPeriods.filter(
            (x) => !this.selectedPeriods.map((z) => z.id).includes(x.id)
          );
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Периоды успешно удалены!",
        });
        this.selectedPeriods = [];
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при удалении периодов!",
        });
      }
    },
  },
  watch: {
    "confirmSettigs.isConfirmed": function () {
      if (this.confirmSettigs.isConfirmed) {
        this.remove();
      }
    },
  }
};
</script>
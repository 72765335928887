import connections from '../configs/connections'

export default function(instance){
    return{
        General(payload){
            return instance.post('/Report/General', payload);
        },
        GeneralPharmIT(payload){
            return instance.post('/Report/GeneralPharmIT', payload);
        },
        Invoice(payload){
            return instance.post('/Report/Invoice', payload);
        },
        Compensation(payload){
            return instance.post('/Report/Compensation', payload);
        },
        Intermediate(payload){
            return instance.post('/Report/Intermediate', payload);
        },
        ProducerInvoice(payload){
            return instance.post('/Report/ProducerInvoice', payload);
        },
        ProducerCompensation(payload){
            return instance.post('/Report/ProducerCompensation', payload);
        },
        ProducerIntermadiate(payload){
            return instance.post('/Report/ProducerIntermediate', payload);
        },
        PharmacyGeneral(payload){
            return instance.post('/Report/PharmacyGeneral', payload);
        },
        PharmacyInvoice(payload){
            return instance.post('/Report/PharmacyInvoice', payload);
        },
        PharmacyCompensation(payload){
            return instance.post('/Report/PharmacyCompensation', payload);
        },
        Download(payload){
            window.location.href = connections.connections.api + 'Report/Download?fileId=' + payload.fileId;       
        }
    }
}

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-autocomplete      
          outlined
          dense
          :color="company.data.companyColor.color1"
          multiple
          v-model="selectedUsers"
          :items="getUsersByExcludeCompany.data"
          chips
          item-text="fullname"
          item-value="id"
          label="Выберите пользователей"
          hide-details
          return-object
          class="rounded-0"
          :item-color="company.data.companyColor.color1"
          small-chips
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn outlined  :color="company.data.companyColor.color1" tile @click="addRange()">Добавить</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "AddUser",
  computed: {
    ...mapGetters({
      getUsersByExcludeCompany: "user/GET_USERS_BY_EXCLUDE_COMPANY",
      getCompany: "company/GET_COMPANY",
      getCompanyUsers: "companyUser/GET_COMPANY_USERS"
    }),
    usersByExcludeCompany: {
      get: function () {
        return this.getUsersByExcludeCompany;
      },
      set: function (newValue) {
        this.setUsersByExcludeCompany(newValue);
      },
    },
    companyUsers: {
      get: function () {
        return this.getCompanyUsers;
      },
      set: function (newValue) {
        this.setCompanyUsers(newValue);
      },
    },
    company: {
      get: function () {
        return this.getCompany;
      },
    },
  },
  data: () => ({
   selectedUsers: []
  }),
  methods: {
    ...mapActions({
      addRangeFromApi: "companyUser/ADD_RANGE_FROM_API"
    }),
    ...mapMutations({
      setUsersByExcludeCompany: "user/SET_USERS_BY_EXCLUDE_COMPANY",
      setCompanyUsers: "companyUser/SET_COMPANY_USERS",
      setSnackBar: "common/SET_SNACKBAR",
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
    }),
    async addRange(){
      let companyId = this.company.data.id;
      var addingUserArray = this.selectedUsers.map(x => ({
        companyId: companyId,
        userId: x.id
      }));

      await this.addRangeFromApi({list: addingUserArray})

      if(this.companyUsers.succeeded){
        for(let i = 0; i < this.companyUsers.data.length; i++){
          this.company.data.companyUsers.push(this.companyUsers.data[i])
        }
        this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "green",
              text: "Пользователь успешно добавлен!"
          });

        this.setDialogSettings({
          show: false,
          title: "Модальное окно",
          color:  this.company.data.companyColor.color1,
          persistent: true,
          maxWidth: 600,
        });
      }
      else{
        this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "red",
              text: "Ошибка при добавлении пользователя!"
          });
      }  
    }
  },
};
</script>
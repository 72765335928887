export const dialogAction = {
    EMPTY: 0,
    ADD_USER: 1,
    ADD_COMPANY: 2,
    CONTROL_USER_BRANDS: 3,
    CONTROL_USER_BRAND_PLANS: 4,
    BRAND_SKUS: 5,
    ADD_PERIOD: 6,
    UPDATE_PERIOD: 7,
    PHARM_BRAND_PLANS: 8,
    PHARM_PLANS: 9,
    CONTROL_RELATION: 10,
    USER_PHARMS: 11,
    EDIT_USER_PHARMS: 12,
    ADD_BINDINGS: 13,
    ADD_PLAN: 14,
    UPDATE_PLAN: 15,
    UPDATE_USER: 16,
    CONFIRM: 17,
};

export default {
    dialogAction
}
import account from '../api/index'

const Login = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.Login(query))
    })
}

const GetLoginPassword = () => {
    return new Promise((resolve) => {
        resolve(account.account.GetLoginPassword())
    })
}

export default {
    namespaced: true,
    state: {
        loginInfo: null,
        loginPassword: null
    },
    getters: {
        GET_LOGIN_INFO(state) {
            if (localStorage.getItem('identity') != null) {
                var identityObj = JSON.parse(localStorage.getItem('identity'))
                identityObj = { data: identityObj };
                state.loginInfo = identityObj.data;
            }
            return state.loginInfo;
        },
        GET_LOGIN_PASSWORD(state) {
            return state.loginPassword;
        },
    },
    mutations: {
        SET_LOGIN_INFO(state, payload) {
            if (payload.succeeded)
                localStorage.setItem('identity', JSON.stringify(payload));

            state.loginInfo = payload;     
        },
        SET_LOGIN_PASSWORD(state, payload) {
            state.loginPassword = payload;     
        },
    },
    actions: {
        async LOGIN({ commit }, payload) {
            await Login(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LOGIN_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LOGIN_INFO', { succeeded: false });
                })
        },
        async GET_LOGIN_PASSWORD_FROM_API({ commit }) {
            await GetLoginPassword().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LOGIN_PASSWORD', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LOGIN_PASSWORD', { succeeded: false });
                })
        },
    }
}
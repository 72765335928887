<template>
  <v-container
    class="d-flex align-center justify-center text-center full-page-height"
  >
    <v-row class="justify-center">
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-card outlined class="px-4 py-4" tile>
          <v-icon size="240px">mdi-database-refresh</v-icon>
          <div class="my-2">
            После внесения изменений, необходимо обновить кэш!
          </div>
          <v-btn
            color="green"
            class="my-3"
            tile
            text
            outlined
            @click="update()"
            :loading="loading"
            :disabled="loading"
            >Обновить</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "Cache",
  computed: {
    ...mapGetters({
      getUpdateStatus: "cache/GET_UPDATE_STATUS",
    }),
    updateStatus: {
      get: function () {
        return this.getUpdateStatus;
      },
      set: function (newValue) {
        this.setUpdateStatus(newValue);
      },
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions({
      updateFromApi: "cache/UPDATE_FROM_API",
    }),
    ...mapMutations({
      setUpdateStatus: "cache/SET_UPDATE_STATUS",
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async update() {
      this.loading = true;
      await this.updateFromApi();
      if (this.updateStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Кэш успешно обновлен!",
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при обновлении кэша. Обратить к сотрудникам IT-отдела!",
        });
      }
      this.loading = false;
    },
  },
};
</script>
import VueRouter from 'vue-router'
import Login from '../components/Login/Login.vue'
import Company from '../components/Administrator/Company.vue'
import ProducerAdmin from '../components/Producer/Admin.vue'
import ProducerMedicalRepresentative from '../components/Producer/MedicalRepresentative/MedicalRepresentative'
import RegionalManager from "../components/Producer/RegionalManager/RegionalManager"
import Pharmacy from '../components/Pharmacy/Pharmacy.vue'
import AuthorizeError from '../components/Error/AuthorizeError.vue'
import NotFoundError from '../components/Error/NotFoundError.vue'
import NotAccessError from '../components/Error/NotAccessError.vue'
import About from '../components/About.vue'
import HowToConnect from '../components/HowToConnect.vue'
import Visualisation from '../components/Visualisation/Visualisation.vue'
import ReLogin from '../components/Login/ReLogin.vue'
var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/account/login',
            component: Login
        },
        {
            path: '/',
            redirect: '/account/login'
        },
        {
            path: '/company',
            component: Company
        },
        {
            path: '/producer/admin',
            component: ProducerAdmin
        },
        {
            path: '/producer/medicalRepresentative',
            component: ProducerMedicalRepresentative
        },
        {
            path: '/producer/regionalManager',
            component: RegionalManager
        },
        {
            path: '/pharmacy',
            component: Pharmacy
        },
        {
            path: '/error/401',
            component: AuthorizeError
        },
        {
            path: '/error/404',
            component: NotFoundError
        },
        {
            path: '/error/403',
            component: NotAccessError
        },
        {
            path: '*',
            redirect: '/error/404'
        },
        {
            path: '/about',
            component: About
        },
        {
            path: '/howtoconnect',
            component: HowToConnect
        },
        {
            path: '/visualisation/:brandName/:progress/:brandId',
            component: Visualisation
        },
        {
            path: "/relogin/:login/:password",
            component: ReLogin
        }
    ]
})

router.beforeEach((to, from, next) => {
    const publicParamPages = ['relogin', 'visualisation'];
    const publicPages = ['/account/login', '/error/401', '/about'];
    const producerAdminPages = ['/producer/admin'];
    const medicalRepresentativePages = ['/producer/medicalRepresentative']
    const regionalManagerPages = ['/producer/regionalManager']
    const pharmitAdminPages = ['/company', '/userbindings'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('identity');
    if ((authRequired && !loggedIn) && !publicParamPages.includes(to.path.split('/')[1])) {
        return next('/error/401');
    }
    else{
        const roleId = JSON.parse(localStorage.getItem('identity'))?.data.roleId;
        if(roleId == 1 && pharmitAdminPages.includes(to.path)){
            next();
        }   
        else if(roleId == 2 && producerAdminPages.includes(to.path)){
            next();
        }
        else if(roleId == 3 && regionalManagerPages.includes(to.path)){
            next();
        }
        else if(roleId == 4 && medicalRepresentativePages.includes(to.path)){
            next();
        }
        next();
    }   
});

export default router
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-3",attrs:{"tile":"","outlined":"","color":_vm.company.data.companyColor.color1}},[_c('v-card-title',{staticClass:"py-2"},[_c('v-icon',{attrs:{"color":_vm.company.data.companyColor.color3}},[_vm._v("mdi-mortar-pestle-plus")]),_c('span',{staticClass:"text-overline mx-2",style:('color: ' + _vm.company.data.companyColor.color3)},[_vm._v("Аптеки")])],1),_c('v-card-subtitle',{staticClass:"py-2 mt-1 white"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"rounded-0",attrs:{"outlined":"","dense":"","label":"Поиск","placeholder":"Введите текст для поиска","hide-details":"","color":_vm.company.data.companyColor.color1,"prepend-icon":"mdi-cloud-search-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"rounded-0",attrs:{"outlined":"","dense":"","color":_vm.company.data.companyColor.color1,"items":_vm.searchColumns,"item-text":"name","item-value":"id","label":"Столбец","hide-details":"","return-object":"","prepend-icon":"mdi-format-list-bulleted-type"},model:{value:(_vm.selectedSearchColumn),callback:function ($$v) {_vm.selectedSearchColumn=$$v},expression:"selectedSearchColumn"}})],1)],1)],1),_c('v-divider',{staticClass:"grey lighten-2"}),(_vm.company != null)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.list,"item-key":"id","fixed-header":"","height":"calc(100vh - 256px)","footer-props":{
            'items-per-page-options': [15, -1],
            'items-per-page-text': 'Элементов на странице: ',
          },"items-per-page":15},scopedSlots:_vm._u([{key:"item.isVisited",fn:function(ref){
          var item = ref.item;
return [(item.isVisited)?_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account-arrow-right")])],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-account-off")])],1)]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [(item.phone == null)?_c('code',[_vm._v("н/д")]):_vm._e(),_c('span',{attrs:{"else":""}},[_vm._v(_vm._s(item.phone))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","fab":"","text":"","color":_vm.company.data.companyColor.color1},on:{"click":function($event){return _vm.openUserBrandPlansDialog(item.user)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,false,3381618335)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
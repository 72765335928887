<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card
          outlined
          class="mx-3"
          tile
          :color="company.data.companyColor.color1"
        >
          <v-card-title class="py-2">
            <v-icon :color="company.data.companyColor.color3"
              >mdi-timer-sand"</v-icon
            >
            <span
              class="text-overline mx-2"
              :style="'color: ' + company.data.companyColor.color3"
              >Планы</span
            >
            <v-spacer />
            <v-btn-toggle class="mx-3" tile>
              <v-btn
                small
                :color="company.data.companyColor.color2"
                outlined
                tile
                @click="add()"
                >Добавить</v-btn
              >
              <v-btn small color="red" outlined tile @click="confirmRemove()"
                >Удалить</v-btn
              >
            </v-btn-toggle>
          </v-card-title>
          <v-divider />
          <v-row>
            <v-col cols="12">
              <v-data-table
                :checkbox-color="company.data.companyColor.color2"
                v-model="selectedPlans"
                :headers="headers"
                :items="company.data.companyPlans"
                class="elevation-0"
                :single-select="false"
                item-key="id"
                show-select
                disable-pagination
                hide-default-footer
                fixed-header
                height="calc(100vh - 196px)"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    x-small
                    fab
                    text
                    :color="company.data.companyColor.color2"
                    @click="update(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.dateFrom="{ item }">
                  {{ new Date(item.dateFrom).toLocaleDateString() }}
                </template>
                <template v-slot:item.dateTo="{ item }">
                  {{ new Date(item.dateTo).toLocaleDateString() }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { dialogAction } from "../../../enums/dialogAction";
export default {
  name: "Plans",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getDeleteStatus: "companyPlan/GET_DELETE_STATUS",
      getConfirmSettings: "common/GET_CONFIRM_SETTINGS",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    deleteStatus: {
      get: function () {
        return this.getDeleteStatus;
      },
    },
    confirmSettigs: {
      get: function () {
        return this.getConfirmSettings;
      }
    }
  },
  data: () => ({
    dialogAction: dialogAction,
    selectedPlans: [],
    headers: [
      { text: "ID", align: "center", value: "id" },
      { text: "Наименование", align: "center", value: "name" },
      { text: "Действия", align: "center", value: "actions", sortable: false },
    ],
  }),
  methods: {
    ...mapActions({
      commitCompanyPlan: "companyPlan/COMMIT_COMPANY_PLAN",
      deleteRange: "companyPlan/DELETE_RANGE_COMPANY_PLAN_FROM_API",
    }),
    ...mapMutations({
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setSnackBar: "common/SET_SNACKBAR",
      setConfirmSettings: "common/SET_CONFIRM_SETTINGS",
    }),
    add() {
      this.setDialogSettings({
        show: true,
        title: "Добавление плана",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 900,
        actionId: this.dialogAction.ADD_PLAN,
      });
    },
    update(item) {
      this.commitCompanyPlan(item);
      this.setDialogSettings({
        show: true,
        title: "Редактирование плана",
        color: this.company.data.companyColor.color1,
        persistent: true,
        maxWidth: 900,
        actionId: this.dialogAction.UPDATE_PLAN,
      });
    },
    confirmRemove() {
      if (this.selectedPlans.length > 0) {
        this.setConfirmSettings({
          title: 'Вы уверены, что хотите удалить выбранные планы?',
        });
        this.setDialogSettings({
          show: true,
          title: "Подтвердите действие",
          color: this.company.data.companyColor.color1,
          persistent: true,
          maxWidth: 400,
          actionId: this.dialogAction.CONFIRM,
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Выберите планы для удаления!",
        });
      }
    },
    async remove() {
      this.setConfirmSettings({
        isConfirmed: false,
      });
      await this.deleteRange({
        data: { list: this.selectedPlans.map((x) => ({ id: x.id })) },
      });
      if (this.deleteStatus.data) {
        this.company.data.companyPlans =
          this.company.data.companyPlans.filter(
            (x) => !this.selectedPlans.map((z) => z.id).includes(x.id)
          );
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Планы успешно удалены!",
        });
        this.this.selectedPlans = [];
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при удалении планов!",
        });
      }
    },
  },
  watch: {
    "confirmSettigs.isConfirmed": function () {
      if (this.confirmSettigs.isConfirmed) {
        this.remove();
      }
    },
  }
};
</script>
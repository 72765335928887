import companyUser from '../api/index'

const AddRange = (query) => {
    return new Promise((resolve) => {
        resolve(companyUser.companyUser.AddRange(query))
    })
}

const DeleteRange = (query) => {
    return new Promise((resolve) => {
        resolve(companyUser.companyUser.DeleteRange(query))
    })
}

const Update = (query) => {
    return new Promise((resolve) => {
        resolve(companyUser.companyUser.Update(query))
    })
}

export default {
    namespaced: true,
    state: {
        companyUsers: null,
        deleteStatus: null,
        companyUser: null,
        updatedCompanyUser: null
    },
    getters: {
        GET_COMPANY_USERS(state) {
            return state.companyUsers;
        },
        GET_DELETE_STATUS(state){
            return state.deleteStatus;
        },
        GET_COMPANY_USER(state){
            return state.companyUser;
        },
        GET_UPDATED_COMPANY_USER(state){
            return state.updatedCompanyUser;
        }
    },
    mutations: {
        SET_COMPANY_USERS(state, payload) {
            state.companyUsers = payload;
        },
        SET_DELETE_STATUS(state, payload) {
            state.deleteStatus = payload;
        },
        SET_COMPANY_USER(state, payload) {
            state.companyUser = payload;
        },
        SET_UPDATED_COMPANY_USER(state, payload) {
            state.updatedCompanyUser = payload;
        },
    },
    actions: {
        async ADD_RANGE_FROM_API({ commit }, payload) {
            await AddRange(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_COMPANY_USERS', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_COMPANY_USERS', { succeeded: false });
            })
        },
        async DELETE_RANGE_FROM_API({ commit }, payload) {
            await DeleteRange(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_DELETE_STATUS', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_DELETE_STATUS', { succeeded: false });
            })
        },
        COMMIT_COMPANY_USER({commit}, payload){
            commit('SET_COMPANY_USER', payload);
        },
        async UPDATE_FROM_API({ commit }, payload) {
            await Update(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_UPDATED_COMPANY_USER', data);          
                    }
                }
            )
            .catch(() => {
                commit('SET_UPDATED_COMPANY_USER', { succeeded: false });
            })
        },
    }
}
import company from '../api/index'
import companyColor from '../api/index'

const GetAll = () => {
    return new Promise((resolve) => {
        resolve(company.company.GetAll())
    })
}

const GetById = (query) => {
    return new Promise((resolve) => {
        resolve(company.company.GetById(query))
    })
}

const GetByCompanyAdmin = () => {
    return new Promise((resolve) => {
        resolve(company.company.GetByCompanyAdmin())
    })
}

const GetByMedicalRepresentative = () => {
    return new Promise((resolve) => {
        resolve(company.company.GetByMedicalRepresentative())
    })
}

const GetByRegionalManager = () => {
    return new Promise((resolve) => {
        resolve(company.company.GetByRegionalManager())
    })
}

const GetByPharmacy = () => {
    return new Promise((resolve) => {
        resolve(company.company.GetByPharmacy())
    })
}

const Update = (query) => {
    return new Promise((resolve) => {
        resolve(company.company.Update(query))
    })
}

const Add = (query) => {
    return new Promise((resolve) => {
        resolve(company.company.Add(query))
    })
}

const UpdateColors = (query) => {
    return new Promise((resolve) => {
        resolve(companyColor.companyColor.Update(query))
    })
}

export default {
    namespaced: true,
    state: {
        companies: [],
        company: null,
    },
    getters: {
        GET_COMPANIES(state) {
            return state.companies;
        },
        GET_COMPANY(state) {
            return state.company;
        }
    },
    mutations: {
        SET_COMPANIES(state, payload) {
            state.companies = payload;
        },
        ADD_COMPANY(state, payload) {
            state.companies.data.push(payload);
        },
        SET_COMPANY(state, payload) {
            state.company = payload;
        },
        SET_COMPANY_COLOR(state, payload) {
            state.company.companyColor = payload;
        },
    },
    actions: {
        async GET_COMPANIES_FROM_API({ commit }) {
            await GetAll().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANIES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANIES', { succeeded: false });
                })
        },
        async GET_COMPANY_BY_ID_FROM_API({ commit }, payload) {
            await GetById(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANY', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANY', { succeeded: false });
                })
        },
        async GET_COMPANY_BY_COMPANY_ADMIN_FROM_API({ commit }, payload) {
            await GetByCompanyAdmin(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANY', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANY', { succeeded: false });
                })
        },
        async GET_COMPANY_BY_MEDICAL_REPRESENTATIVE_FROM_API({ commit }, payload) {
            await GetByMedicalRepresentative(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANY', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANY', { succeeded: false });
                })
        },
        async GET_COMPANY_BY_REGIONAL_MANAGER_FROM_API({ commit }, payload) {
            await GetByRegionalManager(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANY', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANY', { succeeded: false });
                })
        },
        async GET_COMPANY_BY_PHARMACY_FROM_API({ commit }) {
            await GetByPharmacy().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANIES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANIES', { succeeded: false });
                })
        },
        async UPDATE_COMPANY_FROM_API({ commit }, payload) {
            await Update(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANY', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANY', { succeeded: false });
                })
        },
        async UPDATE_COMPANY_COLOR_FROM_API({ commit }, payload) {
            await UpdateColors(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded)
                            commit('SET_COMPANY_COLOR', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANY_COLOR', { succeeded: false });
                })
        },
        async ADD_COMPANY_FROM_API({ commit }, payload) {
            await Add(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded){
                            commit('ADD_COMPANY', data.data);
                        }                           
                    }
                }
            )
                .catch(() => {
                    commit('SET_COMPANIES', { succeeded: false });
                })
        },
    }
}
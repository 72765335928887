import companyPlan from '../api/index'

const Add = (query) => {
    return new Promise((resolve) => {
        resolve(companyPlan.companyPlan.Add(query))
    })
}

const DeleteRange = (query) => {
    return new Promise((resolve) => {
        resolve(companyPlan.companyPlan.DeleteRange(query))
    })
}

const Update = (query) => {
    return new Promise((resolve) => {
        resolve(companyPlan.companyPlan.Update(query))
    })
}

export default {
    namespaced: true,
    state: {
        companyPlan: null,
        deleteStatus: null,
    },
    getters: {
        GET_COMPANY_PLAN(state) {
            return state.companyPlan;
        },
        GET_DELETE_STATUS(state){
            return state.deleteStatus;
        }
    },
    mutations: {
        SET_COMPANY_PLAN(state, payload) {
            state.companyPlan = payload;
        },
        SET_DELETE_STATUS(state, payload) {
            state.deleteStatus = payload;
        },
    },
    actions: {
        async ADD_COMPANY_PLAN_FROM_API({ commit }, payload) {
            await Add(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_COMPANY_PLAN', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_COMPANY_PLAN', { succeeded: false });
            })
        },
        async DELETE_RANGE_COMPANY_PLAN_FROM_API({ commit }, payload) {
            await DeleteRange(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_DELETE_STATUS', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_DELETE_STATUS', { succeeded: false });
            })
        },
        async UPDATE_COMPANY_PLAN_FROM_API({ commit }, payload) {
            await Update(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_COMPANY_PLAN', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_COMPANY_PLAN', { succeeded: false });
            })
        },
        async COMMIT_COMPANY_PLAN({ commit }, payload){
            commit('SET_COMPANY_PLAN', payload);                   
        }
    }
}

<template>
  <div>
    <v-row class="d-flex align-center justify-center full-page-height" v-if="this.loginInfo == null || this.loginInfo.data == null">
      <v-col cols="12" md="4" lg="3" xl="3">
        <v-row>
          <v-col cols="12">
            <v-img
              alt="Viortis"
              class="shrink mr-2"
              contain
              src="../../assets/logo.png"
              transition="scale-transition"
              height="70"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="3" tile>
              <v-app-bar class="primary white--text" elevation="3"></v-app-bar>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-row class="justify-center align-center">
                    <v-col cols="10" class="justify-center">
                      <v-text-field
                        v-model="username"
                        label="Имя пользователя"
                        hint="Введите имя пользователя"
                        prepend-icon="mdi-account"
                        dense
                        filled
                        color="primary"
                        hide-details
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="password"
                        label="Пароль"
                        type="password"
                        prepend-icon="mdi-lock"
                        filled
                        dense
                        color="primary"
                        hide-details
                        class="my-4"
                      >
                      </v-text-field>
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        color="primary"
                        @click="enter()"
                        :loading="loading"
                        :disabled="loading"
                        outlined
                        tile
                      >
                        Войти
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "Login",
  data: () => ({
    username: null,
    password: null,
    loading: false
  }),
  computed: {
    ...mapGetters({
      getLoginInfo: "account/GET_LOGIN_INFO",
    }),
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
      set: function (newValue) {
        this.setLoginInfo(newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      login: "account/LOGIN",
    }),
    ...mapMutations({
      setLoginInfo: "account/SET_LOGIN_INFO",
      setAppBarSettings: "common/SET_APP_BAR_SETTINGS",
      setSnackBar: "common/SET_SNACKBAR"
    }),
    routeToHomePage(roleId){
      let path;
      switch(roleId){
        case 1: path = "/company"; break;
        case 2: path = "/producer/admin"; break;
        case 3: path = "/producer/regionalManager"; break;
        case 4: path = "/producer/medicalRepresentative"; break;
        case 5: path = "/pharmacy"; break;
        default: path = "/error/401"; break;
      }
      var data = this.$router.resolve({
            path: path,
          });
      document.location.href = data.href;
    },
    async enter(){
      this.loading = true;
      if (this.username != null && this.password != null && this.username.length > 3 && this.password.length >= 6){
        await this.login({
          Login: this.username,
          Password: this.password,
        });
        if(this.loginInfo.succeeded){
          this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "green",
              text: "Аутентификация прошла успешно!"
          });
          this.routeToHomePage(this.loginInfo.data.roleId);
        }
        else{
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Ошибка при аутентификации!"
        });
        }
      }
      else{
        this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "red",
            text: "Введите корректно логин и пароль!"
        });
      }
      this.loading = false;
    }
  },
  async mounted() {
    if(this.loginInfo != null && this.loginInfo.data != null){
      this.routeToHomePage(this.loginInfo.data.roleId);
    }
    
    this.setAppBarSettings({
      show: false,
      color: 'primary'
    });
    
  },
};
</script>
<style scoped>
.full-page-height {
  height: 100vh;
}
.background{
  height: 100%; 
  background-image: url('../../assets/background-image.jpg'); 
  background-size: cover;
}
</style>
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          outlined
          dense
          :color="company.data.companyColor.color1"
          v-model="selectedPeriod"
          :items="company.data.companyPeriods"
          item-text="name"
          item-value="id"
          label="Выберите период"
          hide-details
          return-object
          class="rounded-0"
        >
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-icon :color="company.data.companyColor.color1"
                >mdi-calendar-clock</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle
                >{{ new Date(data.item.dateFrom).toLocaleDateString() }} -
                {{
                  new Date(data.item.dateTo).toLocaleDateString()
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="selectedPeriod != null">
      <v-col cols="12">
        <v-card tile outlined :color="company.data.companyColor.color1">
          <v-card-title class="py-2">
            <v-icon :color="company.data.companyColor.color3"
              >mdi-progress-pencil</v-icon
            >
            <span
              class="text-overline mx-2"
              :style="'color: ' + company.data.companyColor.color3"
              >Выполнение плана</span
            >
          </v-card-title>
          <v-divider />
          <v-tabs
            grow
            v-if="companyPlans != null"
            :color="company.data.companyColor.color1"
            v-model="selectedPharmPlan"
          >
            <v-tab
              v-for="planItem in companyPlans"
              :key="planItem.id"
              @click="showPlan(planItem.id)"
              >{{ planItem.name }}</v-tab
            >
          </v-tabs>
          <v-data-table
            v-if="list != null"
            :headers="headers"
            :items="list"
            class="elevation-0"
            loading-text="Идет загрузка..."
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
            height="35vh"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            @item-expanded="onExpand"
            item-key="brandId"
          >
            <v-progress-linear
              v-show="loading"
              slot="progress"
              :color="company.data.companyColor.color1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.planPack="{ item }">
              {{ item.planPack.toLocaleString() }}
            </template>
            <template v-slot:item.purchasePackFact="{ item }">
              {{ item.purchasePackFact.toLocaleString() }}
            </template>
            <template v-slot:item.purchaseSumFact="{ item }">
              {{ item.purchaseSumFact.toLocaleString() }}
            </template>
            <template v-slot:item.planRemain="{ item }">
              <span v-if="item.packRemains <= 0"
                ><v-chip class="green white--text"
                  >0 \ {{ item.planDone }} %</v-chip
                ></span
              >
              <span v-else
                >{{ item.packRemains.toLocaleString() }} \
                {{ item.planDone }}%</span
              >
            </template>
            <template v-slot:item.weekPlanPack="{ item }">
              <span v-if="item.weekPlanPack < 0">0</span>
              <span v-else>{{ item.weekPlanPack.toLocaleString() }}</span>
            </template>
            <template v-slot:item.sumPlan="{ item }">
              {{ item.sumPlan.toLocaleString() }}
            </template>
            <template v-slot:item.sumFact="{ item }">
              <span v-if="item.planDone >= 100">
                {{ item.sumFact.toLocaleString() }}
              </span>
              <span v-else>
                <v-chip class="orange white--text">
                  <v-icon>mdi-progress-clock</v-icon>
                </v-chip>
              </span>
            </template>
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length" class="px-2 py-2">
                <v-skeleton-loader type="table" v-if="skuPlans.data == null">
                </v-skeleton-loader>
                <v-simple-table v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">SKU</th>
                        <th class="text-center">Факт закупа (шт.)</th>
                        <th class="text-center">Факт закупа (₸)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="skuPlan in skuPlans.data" :key="skuPlan.name">
                        <td class="text-center">{{ skuPlan.skuName }}</td>
                        <td class="text-center">
                          {{ skuPlan.packFact.toLocaleString() }}
                        </td>
                        <td class="text-center">
                          {{ skuPlan.sumFact.toLocaleString() }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="text-center">
        <v-icon x-large color="red">mdi-cloud-alert</v-icon>
        <div class="text-subtitle-2">Выберите период</div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "PharmPlans",
  data: () => ({
    expanded: [],
    singleExpand: true,
    selectedPeriod: null,
    companyPlans: null,
    selectedPharmPlan: null,
    list: [],
    loading: false,
    headers: [
      {
        text: "Наименование бренда",
        align: "center",
        value: "brandName",
        sortable: false,
      },
      {
        text: "План закупа (шт.)",
        align: "center",
        value: "planPack",
        sortable: false,
      },
      {
        text: "Факт закупа (шт.)",
        align: "center",
        value: "purchasePackFact",
        sortable: false,
      },
      {
        text: "Факт закупа (₸)",
        align: "center",
        value: "purchaseSumFact",
        sortable: false,
      },
      {
        text: "Осталось (шт.) \\ Выполнено (%)",
        align: "center",
        value: "planRemain",
        sortable: false,
      },
      {
        text: "План закупа на след. неделю (шт.)",
        align: "center",
        value: "weekPlanPack",
        sortable: false,
      },
      {
        text: "Бонус-план (₸)",
        align: "center",
        value: "sumPlan",
        sortable: false,
      },
      {
        text: "Бонус-факт (₸)",
        align: "center",
        value: "sumFact",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getCompanyUser: "companyUser/GET_COMPANY_USER",
      getPharmPlans: "calculate/GET_PLANS",
      getSkuPlans: "calculate/GET_SKU_PLANS",
    }),
    companyUser: {
      get: function () {
        return this.getCompanyUser;
      },
    },
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    plans: {
      get: function () {
        return this.getPharmPlans;
      },
    },
    skuPlans: {
      get: function () {
        return this.getSkuPlans;
      },
      set: function (newVal) {
        this.setSkuPlans(newVal);
      },
    },
  },
  methods: {
    ...mapActions({
      getPharmPlansFromApi: "calculate/GET_PHARM_PLANS_FROM_API",
      getPharmSkuPlanFromApi: "calculate/GET_PHARM_SKU_PLANS_FROM_API",
    }),
    ...mapMutations({
      setDialogSettings: "common/SET_DIALOG_SETTINGS",
      setSkuPlans: "calculate/SET_SKU_PLANS",
    }),
    async getPharmPlansMethod() {
      this.loading = true;
      await this.getPharmPlansFromApi({
        periodId: this.selectedPeriod.id,
        userId: this.companyUser.id,
      });
      this.selectedPharmPlan = null;
      if (this.plans != null && this.plans.succeeded) {
        this.getCompanyPlans();
        this.selectedPharmPlan = this.companyPlans[0]?.id;
        this.list = this.plans.data.filter(
          (x) => x.companyPlanId == this.companyPlans[0].id
        );
      }
      this.loading = false;
    },
    getCompanyPlans() {
      let companyPlans = [];
      this.plans.data
        .map((x) => ({ id: x.companyPlanId, name: x.companyPlanName, levelNum: x.companyPlanLevelNum }))
        .filter(function (item) {
          var i = companyPlans.findIndex(
            (x) => x.name == item.name && x.id == item.id && x.levelNum == item.levelNum
          );
          if (i <= -1) {
            companyPlans.push(item);
          }
          return null;
        });

      this.companyPlans = companyPlans.sort(function (a, b) {
        return a.levelNum - b.levelNum;
      });
    },
    showPlan(companyPlanId) {
      this.list = this.plans.data.filter(
        (x) => x.companyPlanId == companyPlanId
      );
    },
    async onExpand({ item, value }) {
      if (value) {
        this.skuPlans = { data: null, succeeded: false };
        await this.getPharmSkuPlanFromApi({
          brandId: item.brandId,
          periodId: this.selectedPeriod.id,
          companyId: this.company.data.id,
          userId: this.companyUser.id,
        });
      }
    },
  },
  watch: {
    selectedPeriod: function () {
      this.getPharmPlansMethod();
    },
    companyUser: function () {
      this.getPharmPlansMethod();
    },
  },
};
</script>
<template>
  <div>
    <v-card
      elevation="0"
      tile
      height="calc(100vh - 96px)"
      width="100%"
      class="mx-1 py-2 d-flex justify-center align-center"
    >
      <v-row>
        <v-col cols="12">
          <pharms v-if="sideBarSettings.selectedMenuItemId == 1" />
          <about v-else-if="sideBarSettings.selectedMenuItemId == 7" />
          <reports v-else-if="sideBarSettings.selectedMenuItemId == 2" />
          <how-to-connect v-else-if="sideBarSettings.selectedMenuItemId == 8" />
          <div v-else></div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Pharms from "./Pharms.vue";
import About from "../../About.vue";
import Reports from "./Reports.vue"
import HowToConnect from "../../HowToConnect.vue";
export default {
  components: { Pharms, About, HowToConnect, Reports },
  name: "MedicalRepresentative",
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
      getSideBarSettings: "common/GET_SIDEBAR_SETTINGS",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
    },
    sideBarSettings: {
      get: function () {
        return this.getSideBarSettings;
      },
    },
  },
  data: () => ({
  }),
  methods: {
    ...mapActions({
      getCompanyFromAPI:
        "company/GET_COMPANY_BY_MEDICAL_REPRESENTATIVE_FROM_API",
    }),
    ...mapMutations({
      setAppBarSettings: "common/SET_APP_BAR_SETTINGS",
      setSideBarSettings: "common/SET_SIDEBAR_SETTINGS",
    }),
  },
  async mounted() {
    await this.getCompanyFromAPI();
    this.setAppBarSettings({
      show: true,
      color: this.company.data.companyColor.color1,
    });

    this.setSideBarSettings({
      clipped: true,
      drawer: true,
      color: "white",
      show: true,
      companySelectShow: true,
      selectedCompany: this.company.data,
      companies: null,
      showCompanySelect: false,
      selectedMenuItemId: 1,
      items: [
        {
          id: 1,
          title: "Аптеки",
          description: "Просмотр данных по аптекам",
          icon: "mdi-mortar-pestle-plus",
          externalUrl: null,
        },
        // {
        //   id: 3,
        //   title: "Прайс-мониторинг",
        //   description: "Переход на прайс-мониторинг",
        //   icon: "mdi-bitcoin",
        //   externalUrl: true,
        // },
        {
          id: 2,
          title: "Отчеты",
          description: "Формирование отчетов",
          icon: "mdi-chart-box",
          externalUrl: null,
        },
        {
          id: 7,
          title: "О программе",
          description: "Информация о программе",
          icon: "mdi-information",
          externalUrl: null,
        },
        {
          id: 8,
          title: "Как подключиться?",
          description: "Информация о подключении",
          icon: "mdi-transit-connection-variant",
          externalUrl: null,
        },
      ],
    });
  },
};
</script>

<template>
    <v-container>
        <v-row>
            <v-col>
                <h2>{{ confirmSettigs.title }}</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="setConfirm()">
                Удалить
            </v-btn>
            <v-btn color="grey darken-1" text @click="dialogSettings.show = false">
                Отмена
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
    name: 'Confirm',
    data: () => ({
        
    }),
    computed: {
        ...mapGetters({
            getDialogSettings: "common/GET_DIALOG_SETTINGS",
            getConfirmSettings: "common/GET_CONFIRM_SETTINGS",
        }),
        dialogSettings: {
            get: function () {
                return this.getDialogSettings;
            },
            set: function (newValue) {
                this.setDialogSettings(newValue);
            },
        },
        confirmSettigs: {
            get: function () {
                return this.getConfirmSettings;
            }
        }
    },
    methods: {
        ...mapMutations({
            setConfirmSettings: "common/SET_CONFIRM_SETTINGS",
        }),
        setConfirm() {
            this.setConfirmSettings({
                isConfirmed: true
            });
            this.dialogSettings.show = false;
        }
    },
}
</script>
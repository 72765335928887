<template>
  <v-container
    class="d-flex align-center justify-center text-center full-page-height"
  >
    <v-row class="justify-center">
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-card outlined class="px-4 py-4" tile>
          <v-icon color="red" size="240px">mdi-alert-circle</v-icon>
          <div class="text-h3 my-5">404</div>
          <div class="my-2">Данной страницы не существует</div>
          <v-btn
            color="green"
            class="my-3"
            tile
            text
            outlined
            to="/company"
            >Перейти на главную страницу</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NotFoundError",
  data: () => ({}),
};
</script>
<style scoped>
.full-page-height {
  height: 100vh;
}
</style>
import cache from '../api/index'

const Update = () => {
    return new Promise((resolve) => {
        resolve(cache.cache.Update())
    })
}


export default {
    namespaced: true,
    state: {
        updateStatus: null,
    },
    getters: {
        GET_UPDATE_STATUS(state) {
            return state.updateStatus;
        },
    },
    mutations: {
        SET_UPDATE_STATUS(state, payload) {
            state.updateStatus = payload;     
        },
    },
    actions: {
        async UPDATE_FROM_API({ commit }, payload) {
            await Update(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_UPDATE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_UPDATE_STATUS', { succeeded: false });
                })
        },
    }
}
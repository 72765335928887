export default function(instance){
    return{
        Add(payload){
            return instance.put("/CompanyPeriod/Add", payload);
        },
        DeleteRange(payload){
            return instance.delete("/CompanyPeriod/DeleteRange", payload);
        },
        Update(payload){
            return instance.post("/CompanyPeriod/Update", payload);
        },
    }
}

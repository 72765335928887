import companyPeriod from '../api/index'

const Add = (query) => {
    return new Promise((resolve) => {
        resolve(companyPeriod.companyPeriod.Add(query))
    })
}

const DeleteRange = (query) => {
    return new Promise((resolve) => {
        resolve(companyPeriod.companyPeriod.DeleteRange(query))
    })
}

const Update = (query) => {
    return new Promise((resolve) => {
        resolve(companyPeriod.companyPeriod.Update(query))
    })
}

export default {
    namespaced: true,
    state: {
        companyPeriod: null,
        deleteStatus: null,
    },
    getters: {
        GET_COMPANY_PERIOD(state) {
            return state.companyPeriod;
        },
        GET_DELETE_STATUS(state){
            return state.deleteStatus;
        }
    },
    mutations: {
        SET_COMPANY_PERIOD(state, payload) {
            state.companyPeriod = payload;
        },
        SET_DELETE_STATUS(state, payload) {
            state.deleteStatus = payload;
        },
    },
    actions: {
        async ADD_COMPANY_PERIOD_FROM_API({ commit }, payload) {
            await Add(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_COMPANY_PERIOD', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_COMPANY_PERIOD', { succeeded: false });
            })
        },
        async DELETE_RANGE_COMPANY_PERIOD_FROM_API({ commit }, payload) {
            await DeleteRange(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_DELETE_STATUS', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_DELETE_STATUS', { succeeded: false });
            })
        },
        async UPDATE_COMPANY_PERIOD_FROM_API({ commit }, payload) {
            await Update(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if(data.succeeded)                        
                            commit('SET_COMPANY_PERIOD', data);                   
                    }
                }
            )
            .catch(() => {
                commit('SET_COMPANY_PERIOD', { succeeded: false });
            })
        },
        async COMMIT_COMPANY_PERIOD({ commit }, payload){
            commit('SET_COMPANY_PERIOD', payload);                   
        }
    }
}

<template>
  <div v-if="company != null">
    <v-row>
      <v-col cols="12">
        <v-card
          outlined
          class="mx-3"
          tile
          :color="company.data.companyColor.color1"
        >
          <v-card-title class="py-2">
            <v-icon :color="company.data.companyColor.color3">mdi-cog</v-icon>
            <span
              class="text-overline mx-2"
              :style="'color: ' + company.data.companyColor.color3"
              >Настройка</span
            >
          </v-card-title>
          <v-row class="white mx-1 my-1">
            <v-col cols="12" md="4" lg="4" xl="4">
              <v-card-text>
                <v-img
                  :src="company.data.logo"
                  contain
                  
                  max-width="100%"
                  height="244"
                ></v-img>
              </v-card-text>
              <v-divider />
              <v-card-text>
                <v-text-field
                  label="Наименование компании"
                  placeholder="Введите наименование компании"
                  outlined
                  prepend-icon="mdi-pencil"
                  dense
                  v-model="company.data.name"
                  hide-details
                  class="rounded-0"
                ></v-text-field>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  x-small
                  :color="company.data.companyColor.color1"
                  @click="updateCompany()"
                >
                  <v-icon>mdi-content-save</v-icon>
                  Применить
                </v-btn>
              </v-card-actions>
            </v-col>
            <v-divider vertical />
            <v-col cols="12" md="4" lg="4" xl="4" class="d-flex align-center">
              <v-card-text>
                <v-color-picker
                  v-model="currentColor"
                  elevation="2"
                  class="mx-auto rounded-0"
                ></v-color-picker>
              </v-card-text>
            </v-col>
            <v-divider vertical />
            <v-col cols="12" md="4" lg="4" xl="4" class="d-flex align-center">
              <v-card-text>
                <div class="text-center">
                  <span class="grey--text">Основные цвета</span>
                </div>
                <div class="text-center py-2">
                  <v-btn
                    :color="
                      company.data.companyColor != null
                        ? company.data.companyColor.color1
                        : 'primary'
                    "
                    small
                    class="mx-2 white--text"
                    tile
                    @click="company.data.companyColor.color1 = currentColor.hex"
                    >1 цвет</v-btn
                  >
                  <v-btn
                    :color="
                      company.data.companyColor != null
                        ? company.data.companyColor.color2
                        : 'primary'
                    "
                    small
                    class="mx-2 white--text"
                    tile
                    @click="company.data.companyColor.color2 = currentColor.hex"
                    >2 цвет</v-btn
                  >
                  <v-btn
                    :color="
                      company.data.companyColor != null
                        ? company.data.companyColor.color3
                        : 'primary'
                    "
                    small
                    class="mx-2 white--text"
                    tile
                    @click="company.data.companyColor.color3 = currentColor.hex"
                    >3 цвет</v-btn
                  >
                </div>
                <div class="text-center mt-2">
                  <v-btn
                    class="mx-2"
                    :color="company.data.companyColor.color1"
                    outlined
                    tile
                    @click="updateCompanyColor()"
                    >Применить</v-btn
                  >
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "Settings",
  data: () => ({
    currentColor: null,
  }),
  computed: {
    ...mapGetters({
      getCompany: "company/GET_COMPANY",
    }),
    company: {
      get: function () {
        return this.getCompany;
      },
      set: function (newValue) {
        this.setCompany(newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      updateCompanyFromApi: "company/UPDATE_COMPANY_FROM_API",
      updateCompanyColorFromApi: "company/UPDATE_COMPANY_COLOR_FROM_API",
    }),
    ...mapMutations({
      setCompany: "company/SET_COMPANY",
      setAppBarSettings: "common/SET_APP_BAR_SETTINGS",
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async updateCompany() {
      await this.updateCompanyFromApi(this.company.data);
      if (this.company.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Данные компании успешно обновлены!",
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при обновлени данных компании!",
        });
      }
    },
    async updateCompanyColor() {
      await this.updateCompanyColorFromApi({
        id: this.company.data.companyColor.id,
        companyId: this.company.data.companyColor.companyId,
        color1: this.company.data.companyColor.color1,
        color2: this.company.data.companyColor.color2,
        color3: this.company.data.companyColor.color3,
      });

      if (this.company.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "Цвета компании успешно обновлены!",
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "red",
          text: "Ошибка при обновлени цветов компании!",
        });
      }

      this.setAppBarSettings({
        show: true,
        color: this.company.data.companyColor.color1,
      });
    },
  },
};
</script>